import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { jsPDF } from "jspdf";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';

declare var $: any;


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public nuevoEmpresaForm: FormGroup;
  public isAuthLoading = false;


  public user: any = null;
  reporteExport: any;
  findInput: any;
  clientes: any = [];
  pagesNumber: any;
  offset = 3;
  page = 1;
  public cargando = false;
  findInputInstituto: any;
  objInstituto: any = [];
  objCertificados: any = [];

  selectItem: any = [];
  public cargandoExport = false;
  urlSafe: SafeResourceUrl;

  objPlantillaOrder: any = [];
  orientacionPlantilla: any;

  loaded = false;
  msg: any;

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user); 
    this.getList();

    this.nuevoForm = new FormGroup({
      primer_nombre: new FormControl(null, Validators.required),
      segundo_nombre: new FormControl(null, Validators.nullValidator),
      primer_apellido: new FormControl(null, Validators.required),
      segundo_apellido: new FormControl(null, Validators.nullValidator),
      tipo_documento: new FormControl(null, Validators.required),
      documento: new FormControl(null, Validators.required),
      genero: new FormControl(null, Validators.nullValidator),
      pais_nacimiento: new FormControl(null, Validators.nullValidator),
      fecha_nacimiento: new FormControl(null, Validators.nullValidator),
      contacto: new FormControl(null, Validators.nullValidator),
      nivel_educativo: new FormControl(null, Validators.nullValidator),
      area_trabajo: new FormControl(null, Validators.nullValidator),
      cargo_actual: new FormControl(null, Validators.nullValidator),
      sector: new FormControl(null, Validators.nullValidator),
      arl: new FormControl(null, Validators.nullValidator),
      instituto_nombre: new FormControl(null, Validators.nullValidator),
      instituto_representante: new FormControl(null, Validators.nullValidator),
      instituto_nit: new FormControl(null, Validators.nullValidator),
      instituto_id: new FormControl(null, Validators.required),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      primer_nombre: new FormControl(null, Validators.required),
      segundo_nombre: new FormControl(null, Validators.nullValidator),
      primer_apellido: new FormControl(null, Validators.required),
      segundo_apellido: new FormControl(null, Validators.nullValidator),
      tipo_documento: new FormControl(null, Validators.required),
      documento: new FormControl(null, Validators.required),
      genero: new FormControl(null, Validators.nullValidator),
      pais_nacimiento: new FormControl(null, Validators.nullValidator),
      fecha_nacimiento: new FormControl(null, Validators.nullValidator),
      contacto: new FormControl(null, Validators.nullValidator),
      nivel_educativo: new FormControl(null, Validators.nullValidator),
      area_trabajo: new FormControl(null, Validators.nullValidator),
      cargo_actual: new FormControl(null, Validators.nullValidator),
      sector: new FormControl(null, Validators.nullValidator),
      arl: new FormControl(null, Validators.nullValidator),
      instituto_nombre: new FormControl(null, Validators.nullValidator),
      instituto_representante: new FormControl(null, Validators.nullValidator),
      instituto_nit: new FormControl(null, Validators.nullValidator),
      instituto_id: new FormControl(null, Validators.required),
    });

    this.nuevoEmpresaForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      nit: new FormControl(null, Validators.required),
      representante: new FormControl(null, Validators.nullValidator),
    });

  }

  getList() {
    this.cargando = true;
    this.appService.getClientes(this.user.et + '-' + this.page).subscribe((data) => {
      this.clientes = data['clientes'];
      // console.log(this.clientes.items);

      this.cargando = false;
      this.ForpagesNumber();
    });
  }


  changePage(page) {
    this.page = page; //para el filtro
    this.clientes.current = page;
    this.getList();
  }

  ForpagesNumber() {
    let from = this.clientes.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.clientes.total_page) {
      to = this.clientes.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  getCertificadosCliente(cliente_id) {
    this.cargando = true;
    let params = {
      getCliente: true,
      et: this.user.et,
      id: cliente_id
    };
    // console.log(params);
    this.appService.postCertificado(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objCertificados = data['obj'];
          // console.log(this.objCertificados);
        } else {
          this.toastr.error(data["msg"]);
        }
        this.cargando = false;
      });
    this.cargando = false;
  }

  async newCliente() {
    // console.log(this.nuevoForm);

    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        campos: this.nuevoForm.value,
        estado: 1,
      };
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoForm.reset()
            $('#nuevo').modal('hide');
            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  ver(item) {
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('primer_nombre').setValue(item.primer_nombre);
    this.editForm.get('segundo_nombre').setValue(item.segundo_nombre);
    this.editForm.get('primer_apellido').setValue(item.primer_apellido);
    this.editForm.get('segundo_apellido').setValue(item.segundo_apellido);
    this.editForm.get('tipo_documento').setValue(item.tipo_documento);
    this.editForm.get('documento').setValue(item.documento);
    this.editForm.get('genero').setValue(item.genero);
    this.editForm.get('pais_nacimiento').setValue(item.pais_nacimiento);
    this.editForm.get('fecha_nacimiento').setValue(item.fecha_nacimiento);
    this.editForm.get('contacto').setValue(item.contacto);
    this.editForm.get('nivel_educativo').setValue(item.nivel_educativo);
    this.editForm.get('area_trabajo').setValue(item.area_trabajo);
    this.editForm.get('cargo_actual').setValue(item.cargo_actual);
    this.editForm.get('arl').setValue(item.arl);
    this.editForm.get('sector').setValue(item.sector);
    this.editForm.get('instituto_id').setValue(item.instituto_id);
    this.editForm.get('instituto_nombre').setValue(item.instituto_nombre);
    this.editForm.get('instituto_representante').setValue(item.instituto_representante);
    this.editForm.get('instituto_nit').setValue(item.instituto_nit);
    this.getCertificadosCliente(item.id);
    $('#ver').modal('show');
  }

  selectPrint(item) {
    this.selectItem = item;
    this.urlSafe = null;
    // console.log(this.selectItem);
    $('#seleccionModal').modal('show');
  }

  onCargaPdf(tipo, item) {
    let timerInterval
    Swal.fire({
      title: 'Generando Archivo!',
      html: 'Por favor espere<b></b>.',
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          // b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)

        // GENERARDOR DE PDF/////////////////////
        if (tipo == 'certificado') {
          this.objPlantillaOrder = item.plantilla_json;
          this.orientacionPlantilla = item.plantilla_orientacion;
        }
        if (tipo == 'carnet') {
          this.objPlantillaOrder = item.carnet_json;
          this.orientacionPlantilla = item.carnet_orientacion;
        }
        // console.log(this.selectItem);
        // console.log(this.objPlantillaOrder);
        if (!this.orientacionPlantilla) { this.orientacionPlantilla = 'l'; }

        const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'A4');
        const width = doc.internal.pageSize.getWidth()
        const options = { background: 'white', scale: 3, };

        this.objPlantillaOrder.forEach(item => {
          // console.log(item.img_url);

          if (item.modulo == 'img') {
            let img = item.img_url;
            let newImg = img
              .replace("{certificado}", this.selectItem.plantilla_url)
              .replace("{carnet}", this.selectItem.carnet_url)
              .replace("{instructor_firma}", this.selectItem.instructor_firma_url)
              .replace("{representante_firma}", this.selectItem.representante_firma_url);
            // console.log(newImg);
            if (newImg !== 'null') {
              doc.addImage(newImg, "JPG", item.img_x, item.img_y, item.img_w, item.img_h);
            }
          }

          if (item.modulo == 'text') {
            let text = item.text_data;
            // const reg = /\d.*\d/;
            let nombre_cliente = "";
            if (this.selectItem.primer_nombre) { nombre_cliente = this.selectItem.primer_nombre }
            if (this.selectItem.segundo_nombre) { nombre_cliente = nombre_cliente + ' ' + this.selectItem.segundo_nombre }
            if (this.selectItem.primer_apellido) { nombre_cliente = nombre_cliente + ' ' + this.selectItem.primer_apellido }
            if (this.selectItem.segundo_apellido) { nombre_cliente = nombre_cliente + ' ' + this.selectItem.segundo_apellido }


            let newtext = text
              // .replace("{nombre}", this.selectItem.primer_nombre.toUpperCase() + ' ' + this.selectItem.segundo_nombre.toUpperCase()+ ' ' + this.selectItem.primer_apellido.toUpperCase()+ ' ' + this.selectItem.segundo_apellido.toUpperCase())
              .replace("{a}", "á")
              .replace("{e}", "é")
              .replace("{i}", "í")
              .replaceAll("{o}", "ó")
              .replace("{u}", 'ú')
              .replace("{nombre}", nombre_cliente.toUpperCase())
              .replace("{docu}", this.selectItem.tipo_documento.toUpperCase() + ' ' + this.selectItem.documento.toUpperCase())
              .replace("{curso}", this.selectItem.tipo_curso_nombre.toUpperCase())
              .replace("{categoria}", this.selectItem.categoria_nombre.toUpperCase())
              .replace("{ciudad}", this.selectItem.ciudad)
              .replace("{duracion}", this.selectItem.tiempo + ' ' + this.selectItem.duracion)
              .replace("{empresa}", this.selectItem.instituto_nombre.toUpperCase())
              .replace("{empresa_nit}", this.selectItem.instituto_nit)
              .replace("{empresa_representante}", this.selectItem.instituto_representante)
              .replace("{arl}", this.selectItem.arl.toUpperCase())
              .replace("{sector}", this.selectItem.sector)
              .replace("{fecha_inicio}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM'))
              .replace("{fecha_inicio_full}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM [de] YYYY'))
              .replace("{fecha_fin}", moment(this.selectItem.fecha_fin).locale('es').format('DD [de] MMMM [de] YYYY'))
              .replace("{fecha_certificacion}", moment(this.selectItem.fecha_certificacion).locale('es').format('LL'))
              .replace("{fecha_vencimiento}", moment(this.selectItem.fecha_vencimiento).locale('es').format('LL'))
              .replace("{instructor}", this.selectItem.instructor_nombre.toUpperCase())
              .replace("{instructor_licencia}", this.selectItem.instructor_licencia)
              .replace("{representante}", this.selectItem.representante_nombre)
              .replace("{representante_docu}", this.selectItem.representante_documento)
              .replace("{id}", this.selectItem.referencia + '' + this.selectItem.certificado);

            if (item.text_color) { doc.setTextColor(item.text_color); }
            if (item.text_size) { doc.setFontSize(item.text_size); }
            if (item.text_weight) { doc.setFont(undefined, item.text_weight); }
            if (item.text_align == 'center') {
              doc.text(newtext, width / 2 + +item.text_x, item.text_y, null, item.text_align);
            } else {
              doc.text(newtext, item.text_x, item.text_y, null, item.text_align);
            }
          }

          if (item.modulo == 'line') {
            doc.line(item.line_x1, item.line_y1, item.line_x2, item.line_y2);
          }
        });
        var out = doc.output();
        var url = 'data:application/pdf;base64,' + btoa(out);
        // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.cargandoExport = false;
        return doc.save(this.selectItem.tipo_curso_nombre + '-' + this.selectItem.instituto_nombre + '-' + this.selectItem.primer_nombre + '-' + this.selectItem.primer_apellido + '.pdf');

        // FIN GENERARDOR DE PDF/////////////////////

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('temporizador cerrado')
      }
    })
  }

  async editUsuario() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        campos: this.editForm.value,
        sector: this.editForm.value.sector,
      };
      // console.log(params);
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#ver').modal('hide');
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminarUsuario(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al usuario?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {

        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postClientes(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }


  selectEmpresa(item, tipo) {
    if (tipo == 'nuevo') {
      this.nuevoForm.get('instituto_id').setValue(item.id);
      this.nuevoForm.get('instituto_nombre').setValue(item.nombre);
      this.nuevoForm.get('instituto_representante').setValue(item.representante);
      this.nuevoForm.get('instituto_nit').setValue(item.nit);
      $('#nuevo').modal('show');
      $('#institutoModal').modal('hide');
    }
    if (tipo == 'editar') {
      this.editForm.get('instituto_id').setValue(item.id);
      this.editForm.get('instituto_nombre').setValue(item.nombre);
      this.editForm.get('instituto_representante').setValue(item.representante);
      this.editForm.get('instituto_nit').setValue(item.nit);
      $('#ver').modal('show');
      $('#institutoEditModal').modal('hide');
    }
  }

  createEmpresa() {
    $('#crearEmpresa').show();
  }

  async newEmpresa() {
    if (this.nuevoEmpresaForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        campos: this.nuevoEmpresaForm.value,
        estado: 1,
      };
      this.appService.postInstituto(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.findInputInstituto = this.nuevoEmpresaForm.value.nombre;
            this.findInstituto();
            this.nuevoEmpresaForm.reset()
            $('#crearEmpresa').hide();
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  async findCliente() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postClientes(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.clientes = data['clientes'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  async findInstituto() {
    let params = {
      buscar: true,
      buscar_input: this.findInputInstituto,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postInstituto(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objInstituto = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  async exportCliente() {
    let params = {
      export: true,
      et: this.user.et,
    };
    this.appService.postClientes(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.reporteExport = data['clientes'];

          const fileName = 'clientes.xlsx';
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

          // ANCHO COLUMNAS
          ws['!cols'] = [{ width: 2 }, { width: 8 }, { width: 35 }, { width: 35 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
          // ELIMINA COLUMNA1
          ws['!cols'][0] = { hidden: true };
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          // CAMBIA CABECERA
          XLSX.utils.sheet_add_aoa(ws, [["log", "ID", "NOMBRE", "CELULAR", "CIUDAD", "NOMBRE"]]);
          XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });

          XLSX.utils.book_append_sheet(wb, ws, 'reporte');
          XLSX.writeFile(wb, fileName);

        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }


  setDocumento(documento: string) {
    this.loaded = true;
    this.appService.getRegistraduria('verificar?nuip=' + documento).subscribe({
      next: (data) => {

        // console.log(data);
        if (data["success"] == true) {
          let fechaDefuncion = data['data'].fechaDefuncion;

          if (fechaDefuncion.length == 0) {
            let obj = data['data'];

            this.nuevoForm.get('primer_nombre').setValue(obj.primerNombre);
            this.nuevoForm.get('segundo_nombre').setValue(obj.segundoNombre);

            this.nuevoForm.get('primer_apellido').setValue(obj.primerApellido);
            this.nuevoForm.get('segundo_apellido').setValue(obj.segundoApellido);

            this.nuevoForm.get('pais_nacimiento').setValue('colombia');

            if (obj.genero == "MASCULINO") { obj.genero = 'M'; }
            if (obj.genero == "FEMENINO") { obj.genero = 'F'; }
            this.nuevoForm.get('genero').setValue(obj.genero);

            let fecha = obj.fechaNacimiento.split('/');

            // Crear un nuevo objeto Date con los componentes de la fecha
            const nuevaFecha = new Date(Number(fecha[2]), Number(fecha[1]) - 1, Number(fecha[0]));
            // Obtener la fecha formateada en el formato "YYYY-MM-DD"
            this.nuevoForm.get('fecha_nacimiento').setValue(nuevaFecha.toISOString().split('T')[0]);

            this.msg = '';
            this.toastr.success('Ciudadano encontrado');
            // console.log('dentro fechaDefuncion');

          } else {
            this.msg = 'Documento no valido para este producto';
            this.toastr.error('Error al buscar Ciudadano. Intenta mas tarde');

            this.loaded = false;
          }
        }
        this.loaded = false;
      },
      error: (error) => {
        // Manejo de errores para la llamada a postRegistraduria
        console.error('Error al verificar en registraduría:', error);
        this.loaded = false;
        // this.msg = 'Error al verificar el documento. Por favor, inténtalo de nuevo más tarde.';
      }
    });

  }



}

<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-12">
                <h1>
                  <div style="float: left;">Certificados pendientes por aprobar </div>                                    
                  <div class="input-group mb-3 col-3">
                    <div class="input-group" id="button-addon3">
                      <button type="button" (click)="getList()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                      <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                    </div>
                  </div>
                </h1>
              </div>

            </div>

          </div>
          <div class="col-sm-4">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Pendientes</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card" >
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Certificado</th>
            <th scope="col">Curso</th>
            <th scope="col">Duracion</th>
            <th scope="col">Vencimiento</th>
            <th scope="col">Empresa</th>
            <th scope="col">Cliente</th>
            <th scope="col">Convenio</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td (click)="ver(item)" style="cursor: pointer;">{{item.referencia}} {{item.certificado}}</td>
            <td (click)="ver(item)" style="cursor: pointer;">{{item.tipo_curso_nombre | titlecase}} <span *ngIf="item.categoria_nombre" class="text-muted">| {{item.categoria_nombre | titlecase}}</span> </td>
            <td>{{item.tiempo}} {{item.duracion | titlecase}}</td>
            <td>{{item.fecha_vencimiento}}</td>
            <td>{{item.instituto_nombre | titlecase}}</td>
            <td>{{item.primer_nombre | titlecase}} {{item.segundo_nombre | titlecase}} {{item.primer_apellido | titlecase}} {{item.segundo_apellido | titlecase}}</td>
            <td>{{item.convenio | titlecase}}</td>
            <td>
              <button class="btn btn-warning" type="button" (click)="aprobarCertificado(item)" *ngIf="user.perfil =='2fk6i2o3143'"><i class="fa fa-clipboard-check"></i></button>
            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  

</section>




  <!-- Modal Ver  -->
  <div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Certificado Pendiente <span *ngIf="editForm.value.nombre">{{editForm.value.nombre | titlecase}}</span></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-0">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="text-center">{{itemVer.referencia}} {{itemVer.certificado}}</h2>
                  <div class="row border mb-3 p-2" style="border-radius: 6px;">
                    <div class="col-2"><i class="fa fa-clipboard" style="font-size: 3em;"></i></div>
                    <div class="col-10">
                      <h2 *ngIf="!itemVer.curso_id" class="mt-1">Seleccione Tipo Curso</h2>
                      <div *ngIf="itemVer.curso_id" class="mt-1">
                        <h5>{{itemVer.tipo_curso_nombre | titlecase}}</h5>
                        <div class="d-flex w-100 justify-content-between">
                          <small>{{itemVer.categoria_nombre | titlecase}}</small> 
                          <small>{{itemVer.tiempo}} {{itemVer.duracion | titlecase}}</small> 
                          <small>Vigencia: {{itemVer.vigencia}}</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row  border mb-3 p-2" style="border-radius: 6px;">
                    <div class="col-2"><i class="fa fa-clock" style="font-size: 3em;"></i></div>
                    <div class="col-10">
                      <div>
                        <div><small><span class="text-muted">Fecha Inicio</span> {{itemVer.fecha_inicio}}</small></div>
                        <div><small><span class="text-muted">Fecha Fin</span> {{itemVer.fecha_fin}}</small></div>
                        <div><small><span class="text-muted">Vencimiento</span> {{itemVer.fecha_vencimiento}}</small></div>
                      </div>                      
                    </div>
                  </div>

                  <div class="row  border mb-3 p-2" style="border-radius: 6px;">
                    <div class="col-2"><i class="fa fa-building" style="font-size: 3em;"></i></div>
                    <div class="col-10">
                      <div>
                        <div><b>{{itemVer.instituto_nombre | titlecase}}</b></div>
                        <div><small><span class="text-muted">Representante</span> {{itemVer.instituto_representante | titlecase}}</small></div>
                        <div><small><span class="text-muted">Nit</span> {{itemVer.instituto_nit}}</small></div>
                      </div>                      
                    </div>
                  </div>

                  <div class="row border mb-3 p-2" style="border-radius: 6px;">
                    <div class="col-2"><i class="fa fa-user-graduate" style="font-size: 3em;"></i></div>
                    <div class="col-10">
                      <h2 *ngIf="!itemVer.instructor_id" class="mt-1">Seleccione Instructor</h2>
                      <div *ngIf="itemVer.instructor_id" class="mt-1">
                        <h5>{{itemVer.instructor_nombre | titlecase}}</h5>
                        <small>{{itemVer.instructor_licencia}} </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6 mt-0">
                  <div class="form-floating mt-5">
                    <input id="" placeholder="nombre" type="text" value="{{itemVer.primer_nombre | titlecase}} {{itemVer.segundo_nombre | titlecase}} {{itemVer.primer_apellido | titlecase}} {{itemVer.segundo_apellido | titlecase}}" class="form-control" disabled/>
                    <label for="">Nombre Completo</label>
                  </div>
                  <div class="form-floating mt-1">
                    <input id="" placeholder="" type="text" value="{{itemVer.tipo_documento}} {{itemVer.documento }}" class="form-control" disabled/>
                    <label for="">Identificacion</label>
                  </div>
                  <div class="form-floating mt-1">
                    <input id="" placeholder="" type="text" value="{{itemVer.arl | titlecase}}" class="form-control" disabled/>
                    <label for="">ARL</label>
                  </div>
                  <div class="form-floating mt-1">
                    <input id="" placeholder="" type="text" value="Sector {{itemVer.sector }}" class="form-control" disabled/>
                    <label for="">Sector</label>
                  </div>
                  <div class="form-floating mt-1">
                    <input id="" placeholder="" type="text" value="{{itemVer.convenio }}" class="form-control"
                      disabled />
                    <label for="">Convenio</label>
                  </div>
                </div>

                <div *ngIf="user.perfil =='2fk6i2o3143'">
                  <div  class="btn-group w-100" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-warning" (click)="aprobarCertificado(itemVer)"><i class="fa fa-clipboard-check"></i> Aprobar</button>
                    <button type="button" class="btn btn-danger" (click)="eliminarCertificadoCliente(itemVer)"><i class="fa fa-calendar-times"></i> Eliminar</button>
                  </div>              
                </div>
              </div>

        </div>
      </div>
    </div>
  </div>





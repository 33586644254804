<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-5">
                <h1>
                  <div style="float: left;">Nuevo Curso </div>                                    
                </h1>
              </div>

            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Curso</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>
<section class="content m-2">
  <div class="card">
    <div class="card-body">
      <form class="row" [formGroup]="nuevoForm" (ngSubmit)="new()">
        <div class="col-md-6">
          <div class="row border mb-3 p-2" style="border-radius: 6px;">
            <div class="col-2" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#tipocursoModal"><i class="fa fa-clipboard" style="font-size: 5em;"></i></div>
            <div class="col-10" data-bs-toggle="modal" data-bs-target="#tipocursoModal" style="cursor: pointer;">
              <h2 *ngIf="!nuevoForm.value.tipo_curso_id" class="mt-3">Seleccione Tipo Curso</h2>
              <div *ngIf="nuevoForm.value.tipo_curso_id" class="mt-3">
                <h5>{{nuevoForm.value.tipo_curso_nombre | titlecase}}</h5>
                <small>{{nuevoForm.value.categoria_nombre | titlecase}} <span class="ml-3">{{nuevoForm.value.tiempo}} {{nuevoForm.value.duracion | titlecase}}</span> <span class="ml-4">Vigencia: {{nuevoForm.value.vigencia}}</span></small>
              </div>
            </div>
          </div>
          <div class="row border mb-3 p-2" style="border-radius: 6px;">
            <div class="col-2" style="cursor: pointer;"  data-bs-toggle="modal" data-bs-target="#instructorModal"><i class="fa fa-user-graduate" style="font-size: 5em;"></i></div>
            <div class="col-10" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#instructorModal">
              <h2 *ngIf="!nuevoForm.value.instructor_id" class="mt-3">Seleccione Instructor</h2>
              <div *ngIf="nuevoForm.value.instructor_id" class="mt-3">
                <h5>{{nuevoForm.value.instructor_nombre | titlecase}}</h5>
                <small>{{nuevoForm.value.licencia}} </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating">
            <input formControlName="fecha_inicio" placeholder="Fecha Inicio Capacitacion" type="date" class="form-control"/>
            <label >Fecha Inicio Capacitacion <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3">
            <input formControlName="fecha_fin" placeholder="Fecha Fin Capacitacion" type="date" class="form-control"/>
            <label >Fecha Fin Capacitacion <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3">
            <input formControlName="fecha_certificacion" placeholder="Fecha certificacion" type="date" class="form-control"/>
            <label >Fecha certificacion <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3 mb-3">
            <input formControlName="ciudad" placeholder="Ciudad " type="text" class="form-control"/>
            <label >Ciudad  <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3 mb-3">
            <input formControlName="convenio" placeholder="Convenio " type="text" class="form-control" />
            <label >Convenio </label>
          </div>

          <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Curso</app-button>                       
        </div>

      </form>

    </div>
  </div>
</section>


<!-- Listado Tipo Curso -->
<div class="modal fade" id="tipocursoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione curso</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngFor="let item of objTipoCurso" class="list-group-item list-group-item-action" aria-current="true" (click)="selectTipoCurso(item)" style="cursor: pointer;">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{item.nombre | titlecase}}</h5>
            <!-- <small>ID {{item.id}}</small> -->
          </div>
          <p class="mb-1"><span class="text-muted">Categoria: </span> {{item.categoria_nombre | titlecase}}</p>
          <small><span class="text-muted">Duracion: </span> {{item.tiempo}} {{item.duracion | titlecase}}</small>
          <small class="ml-4"><span class="text-muted">Vigencia: </span> {{item.vigencia}}</small>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Listado Instructor -->
<div class="modal fade" id="instructorModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione instructor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngFor="let item of objInstructor" class="list-group-item list-group-item-action" aria-current="true" (click)="selectInstructor(item)" style="cursor: pointer;">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{item.nombre | titlecase}}</h5>
          </div>
          <p class="mb-1"><span class="text-muted">Licencia: </span> {{item.licencia | titlecase}}</p>
          <!-- <small><span class="text-muted">Duracion: </span> {{item.tiempo}} {{item.duracion | titlecase}}</small>
          <small class="ml-4"><span class="text-muted">Vigencia: </span> {{item.vigencia}}</small> -->
        </div>        
      </div>
    </div>
  </div>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Usuarios
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevaUsuario"><i
                class="fa fa-plus"></i></button>
            <button class="btn btn-sm btn-secondary" type="button" (click)="getUsuarios()" *ngIf="!cargando"><i class="fa fa-sync"></i>
            </button>
            <button class="btn btn-sm btn-secondary" type="button" *ngIf="cargando"><span class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true"></span> </button>
          </div>
        </h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Usuarios</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="row">
    <div *ngFor="let item of usuarios.items" class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
      <div class="card d-flex flex-fill">
        <div class="card-header bg-white text-muted border-bottom-0">
          <b>
            <!-- <span *ngIf="item.perfil_id ==4">Logistico</span>  -->
            <span *ngIf="item.perfil_id ==3">Logistico</span> <span *ngIf="item.perfil_id ==2">Administrador</span>
            <span *ngIf="item.perfil_id ==4">Empresa</span></b>
        </div>
        <div class="card-body bg-white pt-0">
          <div class="row">
            <div class="col-9">
              <h2 class="lead"><b>{{item.nombre | titlecase}}</b></h2>
              <h6 class=""><b>{{item.email}}</b></h6>
              <h6 class=""><b>{{item.celular}}</b></h6>
            </div>
            <div class="col-2 text-center">
              <img *ngIf="item.fotografia_url" width="62px" [src]="item.fotografia_url"
                class="user-image img-circle elevation-2" alt="User Image" />
            </div>
          </div>
        </div>
        <div class="card-footer bg-white">
          <div class="text-right">
            <button type="button" class="btn btn-sm btn-primary mr-1" data-bs-toggle="modal"
              data-bs-target="#verUsuario" (click)="verUsuario(item)"><i class="fas fa-user"></i> Ver Usuario</button>
            <button type="button" class="btn btn-sm btn-secondary" (click)="eliminarUsuario(item)"><i
                class="fa fa-trash"></i></button>

          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="usuarios.current > 1">
        <button type="button" class="page-link" (click)="changePage(usuarios.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': usuarios.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="usuarios.current < usuarios.total_page">
        <button type="button" class="page-link" (click)="changePage(usuarios.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{usuarios.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>


<!-- Modal Nueva  -->
<div class="modal fade" id="nuevaUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">

          <form [formGroup]="nuevoForm" (ngSubmit)="newUsuario()">
            <div class="col-12 mt-3" id="crearCliente">

              <div class="form-floating mt-3">
                <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text"
                  class="form-control" />
                <label for="floatingNombre">Nombre <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="email" placeholder="Email" type="text" class="form-control" />
                <label for="">Email <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="password" placeholder="password" type="text" class="form-control" />
                <label for="">password <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="celular" placeholder="celular" type="text" class="form-control" />
                <label for="">celular <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3 mb-3">
                <select formControlName="perfil_id" class="form-select" placeholder="Pefil">
                  <option value="2">Administrador</option>
                  <option value="3">Logistico</option>
                  <option value="4">Empresa</option>
                </select>
                <label for="">Perfil <span class="text-danger">*</span></label>
              </div>

              <div *ngIf="this.nuevoForm.value.perfil_id ==4" class="row  border m-0 mb-2 p-0"
                style="border-radius: 6px;">
                <div class="col-2 p-2 mt-2" style="cursor: pointer;" data-bs-toggle="modal" data-bs-toggle="modal"
                  data-bs-target="#institutoModal"><i class="fa fa-building" style="font-size: 3em;"></i></div>
                <div class="col-10 p-2">
                  <div>
                    <div *ngIf="!this.nuevoForm.value.instituto_id" class="mt-2" style="cursor: pointer;"
                      data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#institutoModal">
                      <h5>Seleccione Empresa <span class="text-danger">*</span></h5>
                    </div>
                    <div *ngIf="this.nuevoForm.value.instituto_id"><b>{{this.nuevoForm.value.instituto_nombre |
                        titlecase}}</b></div>
                    <div *ngIf="this.nuevoForm.value.instituto_id"><small><span class="text-muted">Representante</span>
                        {{this.nuevoForm.value.instituto_representante | titlecase}}</small></div>
                    <div *ngIf="this.nuevoForm.value.instituto_id"><small><span class="text-muted">Nit</span>
                        {{this.nuevoForm.value.instituto_nit}}</small></div>
                  </div>
                </div>
              </div>

              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Usuario</app-button>

            </div>

          </form>

        </div>

      </div>
    </div>
  </div>
</div>



<!-- buscar Empresa Nuevo -->
<div class="modal fade" id="institutoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Empresa</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <div class="input-group mb-0">
            <input type="text" class="form-control" [(ngModel)]="findInputInstituto" placeholder="Buscador">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findInstituto()"><i
                class="fa fa-search"></i></button>
          </div>
        </div>

        <div *ngFor="let item of objInstituto.items" class="list-group-item list-group-item-action" aria-current="true"
          (click)="selectEmpresa(item,'nuevo')" style="cursor: pointer;">
          <div class="row">
            <div class="col-10">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.nombre | titlecase}} {{item.apellido | titlecase}}</h5>
              </div>
              <p class="mb-1"><span class="text-muted">Representante:</span> {{item.representante}}</p>
              <p class="mb-1"><span class="text-muted" *ngIf="item.nit">Nit </span>{{item.nit }}</p>
            </div>
            <div class="col-2">
              <i class="fa fa-building" style="color: #ced4da;font-size: 3em;"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- buscar Empresa Edit -->
<div class="modal fade" id="institutoEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Empresa</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <div class="input-group mb-0">
            <input type="text" class="form-control" [(ngModel)]="findInputInstituto" placeholder="Buscador">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findInstituto()"><i
                class="fa fa-search"></i></button>
          </div>
        </div>

        <div *ngFor="let item of objInstituto.items" class="list-group-item list-group-item-action" aria-current="true"
          (click)="selectEmpresa(item,'editar')" style="cursor: pointer;">
          <div class="row">
            <div class="col-10">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.nombre | titlecase}} {{item.apellido | titlecase}}</h5>
              </div>
              <p class="mb-1"><span class="text-muted">Representante:</span> {{item.representante}}</p>
              <p class="mb-1"><span class="text-muted" *ngIf="item.nit">Nit </span>{{item.nit }}</p>
            </div>
            <div class="col-2">
              <i class="fa fa-building" style="color: #ced4da;font-size: 3em;"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal Ver  -->
<div class="modal fade" id="verUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">

          <form [formGroup]="editForm" (ngSubmit)="editUsuario()">
            <div class="row">
              <div class="col-3 mt-3">
                <img *ngIf="editForm.value.fotografia_url" [src]="editForm.value.fotografia_url"
                  class="user-image img-circle elevation-2 img-fluid" alt="User Image" />
              </div>
              <div class="col-9 mt-3">
                <div class="form-floating mt-3">
                  <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                  <label for="floatingNombre">Nombre</label>
                </div>

                <div class="form-floating mt-3">
                  <input formControlName="email" placeholder="Email" type="text" class="form-control"
                    [attr.disabled]="true" />
                  <label for="floatingNombre">Email</label>
                </div>


                <div class="form-floating mt-3">
                  <input formControlName="password" placeholder="Contraseña" type="text" class="form-control" />
                  <label for="floatingNombre">Contraseña</label>
                </div>

                <div class="form-floating mt-3">
                  <input formControlName="celular" placeholder="celular" type="text" class="form-control" />
                  <label for="floatingNombre">celular</label>
                </div>

                <div class="form-floating mt-3 mb-3">
                  <select formControlName="perfil_id" class="form-select" placeholder="Pefil">
                    <option value="2">Administrador</option>
                    <option value="3">Logistico</option>
                    <option value="4">Empresa</option>
                  </select>
                  <label for="">Perfil</label>
                </div>
                <div *ngIf="this.editForm.value.perfil_id ==4" class="row  border m-0 mb-2 p-0"
                  style="border-radius: 6px;">
                  <div class="col-2 p-2 mt-2" style="cursor: pointer;" data-bs-toggle="modal" data-bs-toggle="modal"
                    data-bs-target="#institutoEditModal"><i class="fa fa-building" style="font-size: 3em;"></i></div>
                  <div class="col-10 p-2">
                    <div>
                      <div *ngIf="!this.editForm.value.instituto_id" class="mt-2" style="cursor: pointer;"
                        data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#institutoEditModal">
                        <h5>Seleccione Empresa <span class="text-danger">*</span></h5>
                      </div>
                      <div *ngIf="this.editForm.value.instituto_id"><b>{{this.editForm.value.instituto_nombre |
                          titlecase}}</b></div>
                      <div *ngIf="this.editForm.value.instituto_id"><small><span class="text-muted">Representante</span>
                          {{this.editForm.value.instituto_representante | titlecase}}</small></div>
                      <div *ngIf="this.editForm.value.instituto_id"><small><span class="text-muted">Nit</span>
                          {{this.editForm.value.instituto_nit}}</small></div>
                    </div>
                  </div>
                </div>

                <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Usuario</app-button>
              </div>
            </div>

          </form>

        </div>

      </div>
    </div>
  </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="user.emant">
  <b>Servicio Temporalmente Suspendido.</b> <br>Recuerde las fechas limites para el pago de su facturación y evitar
  contratiempos en los procesos. Cualquier duda contáctenos.
</div>
<section class="content m-2">
  <div class="card">
    <div class="card-body">
      <!-- <form class="row" [formGroup]="nuevoForm" (ngSubmit)="new()"> -->
      <div class="row">
        <div class="col-md-6">
          <div class="w100 d-flex justify-content-between">
            <h2><button class="btn btn-sm btn-outline-secondary" type="button" routerLink="/cursos/"><i
                  class="fa fa-chevron-left"></i></button> Id {{obj.id}}</h2>
            <div><button class="btn btn-sm btn-warning" type="button" (click)="selectEditCurso()"
                *ngIf="user.perfil =='2fk6i2o3143' || user.perfil =='3af3s12f153'"><i class="fa fa-edit"></i></button>
            </div>
          </div>
          <div class="row border mb-3 p-2" style="border-radius: 6px;">
            <div class="col-2"><i class="fa fa-clipboard" style="font-size: 5em;"></i></div>
            <div class="col-10">
              <h2 *ngIf="!obj.tipo_curso_id" class="mt-3">Seleccione Tipo Curso</h2>
              <div *ngIf="obj.tipo_curso_id" class="mt-3">
                <h5>{{obj.tipo_curso_nombre | titlecase}}</h5>
                <small>{{obj.categoria_nombre | titlecase}} <span class="ml-3">{{obj.tiempo}}
                    {{obj.duracion | titlecase}}</span> <span class="ml-4">Vigencia:
                    {{obj.vigencia}}</span></small>
              </div>
            </div>
          </div>
          <div class="row border mb-3 p-2" style="border-radius: 6px;">
            <div class="col-2"><i class="fa fa-user-graduate" style="font-size: 5em;"></i></div>
            <div class="col-10">
              <h2 *ngIf="!obj.instructor_id" class="mt-3">Seleccione Instructor</h2>
              <div *ngIf="obj.instructor_id" class="mt-3">
                <h5>{{obj.instructor_nombre | titlecase}}</h5>
                <small>{{obj.licencia}} </small>
              </div>
            </div>
          </div>



        </div>
        <div class="col-md-6">
          <div class="form-floating">
            <input [value]="obj.fecha_inicio" placeholder="Fecha Inicio Capacitacion" type="date" class="form-control"
              disabled />
            <label>Fecha Inicio Capacitacion <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3">
            <input [value]="obj.fecha_fin" placeholder="Fecha Fin Capacitacion" type="date" class="form-control"
              disabled />
            <label>Fecha Fin Capacitacion <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3">
            <input [value]="obj.fecha_certificacion" placeholder="Fecha certificacion" type="date" class="form-control"
              disabled />
            <label>Fecha certificacion <span class="text-danger">*</span></label>
          </div>

          <div class="form-floating mt-3 mb-3">
            <input [value]="obj.ciudad" placeholder="Ciudad " type="text" class="form-control" disabled />
            <label>Ciudad</label>
          </div>

          <div class="form-floating mt-3 mb-3">
            <input [value]="obj.convenio" placeholder="Convenio " type="text" class="form-control" disabled />
            <label>Convenio </label>
          </div>

          <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Curso</app-button>                        -->
        </div>
      </div>
      <!-- </form> -->

    </div>
  </div>
</section>

<!-- Listado Instructor -->
<div class="modal fade" id="instructorModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione instructor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngFor="let item of objInstructor" class="list-group-item list-group-item-action" aria-current="true"
          (click)="selectInstructor(item)" style="cursor: pointer;">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{item.nombre | titlecase}}</h5>
          </div>
          <p class="mb-1"><span class="text-muted">Licencia: </span> {{item.licencia | titlecase}}</p>
          <!-- <small><span class="text-muted">Duracion: </span> {{item.tiempo}} {{item.duracion | titlecase}}</small>
          <small class="ml-4"><span class="text-muted">Vigencia: </span> {{item.vigencia}}</small> -->
        </div>
      </div>
    </div>
  </div>
</div>

<section class="content m-2">
  <div class="card">
    <div class="card-body">
      <div class="text-center mb-3">
        <button class="btn btn-secondary ml-1" *ngIf="!cargando" type="button" (click)="getCertificados()"><i
            class="fa fa-sync"></i></button>
        <button type="button" *ngIf="cargando" class="btn btn-secondary ml-1"><span
            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
        <button [disabled]="obj.estado ==1" class="btn btn-primary w-50 ml-1" type="button" data-bs-toggle="modal"
          data-bs-target="#clienteModal"><i class="fa fa-certificate"></i> Certificar</button>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button [disabled]="obj.estado ==1" (click)="cerrarCurso(obj,1)" class="btn btn-secondary ml-1" type="button"
            data-bs-toggle="tooltip" data-bs-placement="top" title="Cerrar curso"><i class="fa fa-lock"></i></button>
          <button [disabled]="obj.estado ==3" *ngIf="user.perfil =='2fk6i2o3143'" (click)="cerrarCurso(obj,3)"
            class="btn btn-secondary ml-1" type="button" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Cerrar curso"><i class="fa fa-unlock"></i></button>
          <button [disabled]="obj.estado ==1" class="btn btn-secondary ml-1" *ngIf="!cargando" type="button"
            (click)="sincronizarCurso()"><i class="fa fa-network-wired"></i></button>
          <button (click)="exportCertificadoRelacion()" class="btn btn-success" type="button"><i
              class="fa fa-file-excel"></i></button>
          <!-- <button [disabled]="obj.estado ==3" (click)="exportCertificadoRelacion()" class="btn btn-success"
            type="button"><i class="fa fa-file-excel"></i></button> -->
          <button (click)="selectInformeSupervisor()" class="btn btn-warning" type="button"><i
              class="fa fa-file-excel"></i> Informe supervisor</button>
        </div>

      </div>

      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Certificado</th>
            <th scope="col">Nombre</th>
            <th scope="col">Identificacion</th>
            <th scope="col">Empresa</th>
            <th scope="col"></th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of objCertificados">
            <td><span *ngIf="item.referencia">{{item.referencia}}</span> {{item.certificado}}</td>
            <td>{{item.primer_nombre | titlecase}} {{item.segundo_nombre | titlecase}} {{item.primer_apellido |
              titlecase}} {{item.segundo_apellido | titlecase}}</td>
            <td>{{item.tipo_documento}} {{item.documento}}</td>
            <td>{{item.instituto_nombre | titlecase}}</td>
            <td>
              <span *ngIf="user.emant">
                <button [disabled]="item.estado >=1" class="btn btn-danger" type="button"><i
                    class="fa fa-print"></i></button>
              </span>
              <span *ngIf="!user.emant">
                <button [disabled]="item.estado ==3" *ngIf="item.estado ==3" class="btn btn-outline-secondary"
                  type="button" data-bs-toggle="modal" data-bs-target="#seleccionModal"><i
                    class="fa fa-print"></i></button>
                <button *ngIf="item.estado ==1" class="btn btn-warning" type="button" (click)="selectPrint(item)"><i
                    class="fa fa-print"></i></button>
              </span>
              <span *ngIf="user.perfil =='2fk6i2o3143'">
                <button [disabled]="obj.estado ==1" class="btn btn-secondary ml-1" type="button"
                  (click)="eliminarCertificadoCliente(item)"><i class="fa fa-trash"></i></button>
              </span>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>

</section>



<!-- buscar clientes -->
<div class="modal fade" id="clienteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Cliente</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <div class="input-group mb-0">
            <input type="text" class="form-control" [(ngModel)]="findInput"
              placeholder="Buscador por nombre o documento">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findCliente()"><i
                class="fa fa-search"></i></button>
          </div>
        </div>

        <div *ngFor="let item of objClientes.items" class="list-group-item list-group-item-action" aria-current="true"
          (click)="certificarCliente(item)" style="cursor: pointer;">
          <div class="row">
            <div class="col-10">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.primer_nombre | titlecase}} {{item.segundo_nombre | titlecase}}
                  {{item.primer_apellido | titlecase}} {{item.segundo_apellido | titlecase}}</h5>
              </div>
              <p class="mb-1"><span class="text-muted">{{item.tipo_documento}}: </span> {{item.documento}} </p>
              <p class="mb-1" *ngIf="item.arl">ARL {{item.arl | titlecase}}</p>
              <div class="mb-1" *ngIf="item.instituto_nombre">
                <div>{{item.instituto_nombre | titlecase}}</div>
                <div>{{item.instituto_nit}} </div>
                <div>{{item.instituto_representante | titlecase}}</div>
              </div>
            </div>
            <div class="col-2">
              <i class="fa fa-certificate" style="color: #ffc107;font-size: 3em;"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- Seleccion Plantilla o Carnet  -->
<div class="modal fade" id="seleccionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione formato </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="btn-group" role="group" aria-label="">

            <button [disabled]="!selectItem.plantilla_json" class="btn btn-primary" type="button"
              (click)="onCargaPdf('certificado',selectItem)">
              <i class="fa fa-certificate"></i> Certificado</button>
            <button [disabled]="!selectItem.carnet_json" class="btn btn-primary ml-1" type="button"
              (click)="onCargaPdf('carnet',selectItem)"><i class="fa fa-address-card"></i> Carnet</button>
          </div>

          <div *ngIf="cargando">
            <h3><span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span> Cargando</h3>
          </div>
        </div>
        <!-- PDF DINAMIC -->
        <!-- <div *ngIf="urlSafe" class="mt-3 embed-responsive embed-responsive-1by1">
          <iframe class="embed-responsive-item" *ngIf="urlSafe" class="thumbnail" [src]="urlSafe" frameborder="0"
            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- Edit curso  -->
<div class="modal fade" id="editCursoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar curso </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="edit()">
          <div class="row">
            <div class="col-md-8">

              <div class="row border mb-3 p-2" style="border-radius: 6px;">
                <div class="col-2" data-bs-toggle="modal" data-bs-target="#tipocursoModal" style="cursor: pointer;"><i
                    class="fa fa-clipboard" style="font-size: 5em;"></i></div>
                <div class="col-10" data-bs-toggle="modal" data-bs-target="#tipocursoModal" style="cursor: pointer;">
                  <h2 *ngIf="!obj.tipo_curso_id" class="mt-3">Seleccione Tipo Curso</h2>
                  <div *ngIf="obj.tipo_curso_id" class="mt-3">
                    <h5>{{obj.tipo_curso_nombre | titlecase}}</h5>
                    <small>{{obj.categoria_nombre | titlecase}} <span class="ml-3">{{obj.tiempo}}
                        {{obj.duracion | titlecase}}</span> <span class="ml-4">Vigencia:
                        {{obj.vigencia}}</span></small>
                  </div>
                </div>
              </div>
              <div class="row border mb-3 p-2" style="border-radius: 6px;">
                <div class="col-2" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#instructorModal"><i
                    class="fa fa-user-graduate" style="font-size: 5em;"></i></div>
                <div class="col-10" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#instructorModal">
                  <h2 *ngIf="!obj.instructor_id" class="mt-3">Seleccione Instructor</h2>
                  <div *ngIf="obj.instructor_id" class="mt-3">
                    <h5>{{obj.instructor_nombre | titlecase}}</h5>
                    <small>{{obj.licencia}} </small>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input formControlName="fecha_inicio" placeholder="Fecha Inicio Capacitacion" type="date"
                  class="form-control" />
                <label>Fecha Inicio Capacitacion <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="fecha_fin" placeholder="Fecha Fin Capacitacion" type="date"
                  class="form-control" />
                <label>Fecha Fin Capacitacion <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="fecha_certificacion" placeholder="Fecha certificacion" type="date"
                  class="form-control" />
                <label>Fecha certificacion <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3 mb-3">
                <input formControlName="ciudad" placeholder="Ciudad " type="text" class="form-control" />
                <label>Ciudad <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3 mb-3">
                <input formControlName="convenio" placeholder="Convenio " type="text" class="form-control" />
                <label>Convenio </label>
              </div>

              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Curso</app-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal informe de supervisor  -->
<div class="modal fade" id="informeSupervisorModal" tabindex="-1" aria-labelledby="exampleModal2Label"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Informe de supervisor de formación</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="editFormacionInforme()">
          <!-- Grupo INFORMACION GENERAL -->
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <!-- Primera columna -->
                    <div class="col-md-6">
                      <div class="form-floating mt-3 mb-3">
                        <input formControlName="tipo_curso_nombre" placeholder="Nombre del curso" type="text"
                          class="form-control" disabled="disabled" />
                        <label>Nombre del curso</label>
                      </div>

                      <div class="form-floating mt-3 mb-3">
                        <input formControlName="instructor_nombre" placeholder="Entrenador" type="text"
                          class="form-control" disabled="disabled" />
                        <label>Entrenador 1</label>
                      </div>

                      <div class="form-floating mt-3 mb-3">
                        <input formControlName="instructor_nombre_2" placeholder="Entrenador" type="text"
                          class="form-control" />
                        <label>Entrenador 2</label>
                      </div>

                      <div class="form-floating mt-3 mb-3">
                        <input formControlName="instructor_nombre_3" placeholder="Entrenador" type="text"
                          class="form-control" />
                        <label>Entrenador 3</label>
                      </div>                      

                    </div>

                    <!-- Segunda columna -->
                    <div class="col-md-6">
                      <div class="form-floating mt-3 mb-3">
                        <input formControlName="supervisor" placeholder="Supervisor" type="text" class="form-control" />
                        <label>Supervisor</label>
                      </div> 

                      <div class="form-floating mt-3 mb-3">
                        <input formControlName="ayudante" placeholder="Ayudante de seguridad" type="text"
                          class="form-control" />
                        <label>Ayudante de seguridad</label>
                      </div>

                      <div class="form-floating">
                        <input formControlName="fecha_inicio" placeholder="Fecha Inicio Capacitacion" type="date"
                          class="form-control" disabled="disabled" />
                        <label>Fecha Inicio Capacitacion</label>
                      </div>

                      <div class="form-floating mt-3">
                        <input formControlName="codigo_ministerio" placeholder="Código del ministerio" type="text"
                          class="form-control" />
                        <label>Código del ministerio</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Grupo REQUISITOS DE INGRESO -->
          <div class="card mt-4">
            <div class="card-header d-flex align-items-center">
              <h5 class="mb-0">REQUISITOS DE INGRESO</h5>
              <button type="button" class="btn btn-sm ms-auto"
                [ngClass]="editForm.get('req_habilitar')?.value ? 'btn-danger' : 'btn-success'"
                (click)="editForm.patchValue({ req_habilitar: editForm.get('req_habilitar')?.value ? 0 : 1 })">
                {{ editForm.get('req_habilitar')?.value ? 'Deshabilitar' : 'Habilitar' }}
              </button>
            </div>


            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="req_documento" />
                    <label class="form-check-label">Copia de documento de identidad legible.</label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="req_afiliaciones" />
                    <label class="form-check-label">Soporte de pago o afiliaciones a seguridad social (ARL -
                      Salud).</label>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="req_concepto_medico" />
                    <label class="form-check-label">Concepto médico ocupacional con énfasis en alturas vigente.</label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="req_certificado" />
                    <label class="form-check-label">Certificado o constancia de formación vocacional de curso nivel
                      avanzado o trabajador autorizado.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Grupo VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA DE FORMACIÓN Y ENTRENAMIENTO -->
          <div class="card mt-4">
            <div class="card-header d-flex align-items-center">
              <h5 class="mb-0">VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA DE FORMACIÓN Y ENTRENAMIENTO</h5>
              <button type="button" class="btn btn-sm ms-auto"
                [ngClass]="editForm.get('cumpli_habilitar')?.value ? 'btn-danger' : 'btn-success'"
                (click)="editForm.patchValue({ cumpli_habilitar: editForm.get('cumpli_habilitar')?.value ? 0 : 1 })">
                {{ editForm.get('cumpli_habilitar')?.value ? 'Deshabilitar' : 'Habilitar' }}
              </button>
            </div>

            <div class="card-body">
              <table class="table table-bordered text-center">
                <thead>
                  <tr>
                    <th class="text-start">ITEMS</th>
                    <th>C</th>
                    <th>NC</th>
                    <th>N/A</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-start">Requisitos de ingreso por parte de los aprendices y/o empleador de acuerdo
                      con el programa de formación.</td>
                    <td><input type="radio" formControlName="cumpli_requisitos" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_requisitos" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_requisitos" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Disponibilidad de material, equipos, estructuras, material didáctico y demás
                      elementos necesarios para cumplir con los objetivos del programa de formación.</td>
                    <td><input type="radio" formControlName="cumpli_disponibilidad" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_disponibilidad" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_disponibilidad" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Verificación del material, equipos, estructuras, material didáctico y demás
                      elementos necesarios sean inspeccionados y se les realice mantenimiento para asegurar que se
                      mantengan las condiciones de seguridad durante el desarrollo del programa de formación.</td>
                    <td><input type="radio" formControlName="cumpli_material" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_material" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_material" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Verificar que las áreas de entrenamiento estén restringidas durante los
                      periodos de descanso.</td>
                    <td><input type="radio" formControlName="cumpli_area" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_area" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_area" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Verificar la asistencia del 100% por parte de los aprendices de acuerdo con
                      la intensidad horaria del programa de formación.</td>
                    <td><input type="radio" formControlName="cumpli_asistencia" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_asistencia" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_asistencia" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Verificar la aprobación de las evaluaciones de formación y entrenamiento de
                      acuerdo con lo establecido con el programa de formación.</td>
                    <td><input type="radio" formControlName="cumpli_evaluacion" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_evaluacion" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_evaluacion" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Garantizar el cumplimiento del 100% del plan de estudios, de acuerdo con el
                      programa de formación.</td>
                    <td><input type="radio" formControlName="cumpli_cumpliento" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_cumpliento" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_cumpliento" value="N/A"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Se garantiza durante la etapa de entrenamiento acompañamiento permanente de
                      una (1) persona de apoyo, con capacidad de operar el plan de emergencia, labores de rescate,
                      vigilar las actividades realizadas por los aprendices en entrenamiento y brindar soporte en la
                      atención de primeros auxilios.</td>
                    <td><input type="radio" formControlName="cumpli_apoyo" value="C"></td>
                    <td><input type="radio" formControlName="cumpli_apoyo" value="NC"></td>
                    <td><input type="radio" formControlName="cumpli_apoyo" value="N/A"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Nuevo Campo de Observaciones -->
          <div class="form-group mt-3">
            <label for="observaciones"><strong>Observaciones</strong></label>
            <textarea id="observaciones" class="form-control" rows="3" formControlName="observaciones"
              placeholder="Ingrese observaciones..."></textarea>
          </div>

          <div class="btn-group mt-4" role="group" aria-label="Basic mixed styles example">
            <button type="submit" class="btn btn-primary">Actualizar Informe</button>
            <button type="button" (click)="generatePDF(this.editForm.value)" class="btn btn-success">
              <i class="fa fa-download"></i> Descargar PDF
            </button>
            <!-- <button type="button" (click)="generateEXCEL(this.editForm.value)" class="btn btn-success">
              <i class="fa fa-download"></i> Descargar EXCEL
            </button> -->
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<!-- Listado Tipo Curso -->
<div class="modal fade" id="tipocursoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione curso</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngFor="let item of objTipoCurso" class="list-group-item list-group-item-action" aria-current="true"
          (click)="selectTipoCurso(item)" style="cursor: pointer;">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{item.nombre | titlecase}}</h5>
          </div>
          <p class="mb-1"><span class="text-muted">Categoria: </span> {{item.categoria_nombre | titlecase}}</p>
          <small><span class="text-muted">Duracion: </span> {{item.tiempo}} {{item.duracion | titlecase}}</small>
          <small class="ml-4"><span class="text-muted">Vigencia: </span> {{item.vigencia}}</small>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Listado Instructor -->
<div class="modal fade" id="instructorModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione instructor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngFor="let item of objInstructor" class="list-group-item list-group-item-action" aria-current="true"
          (click)="selectInstructor(item)" style="cursor: pointer;">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{item.nombre | titlecase}}</h5>
          </div>
          <p class="mb-1"><span class="text-muted">Licencia: </span> {{item.licencia | titlecase}}</p>
          <!-- <small><span class="text-muted">Duracion: </span> {{item.tiempo}} {{item.duracion | titlecase}}</small>
          <small class="ml-4"><span class="text-muted">Vigencia: </span> {{item.vigencia}}</small> -->
        </div>
      </div>
    </div>
  </div>
</div>
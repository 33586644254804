import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-ver-curso',
  templateUrl: './ver-curso.component.html',
  styleUrls: ['./ver-curso.component.scss']
})
export class VerCursoComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  id: any;
  public user: any = null;
  public isAuthLoading = false;
  obj: any = [];
  objTipoCurso: any = [];
  objInstructor: any = [];
  objClientes: any = [];
  objCertificados: any = [];
  findInput: any;
  clientes: any = [];
  selectItem: any = [];
  public cargando = false;
  public cargandoExport = false;
  reporteExport: any;
  urlSafe: SafeResourceUrl;

  objPlantillaOrder: any = [];
  orientacionPlantilla: any;


  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.user = this.appService.user;
    // console.log(this.user);


    if (this.id) {
      this.getCurso();
      this.getCertificados();
    } else {
      this.router.navigate(['/cursos/']);
    }

    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      instructor_id: new FormControl(null, Validators.required),
      instructor_nombre: new FormControl(null, Validators.nullValidator),
      licencia: new FormControl(null, Validators.nullValidator),

      tipo_curso_id: new FormControl(null, Validators.required),
      tipo_curso_nombre: new FormControl(null, Validators.nullValidator),
      categoria_nombre: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.nullValidator),
      duracion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),

      fecha_inicio: new FormControl(null, Validators.nullValidator),
      fecha_fin: new FormControl(null, Validators.nullValidator),
      fecha_certificacion: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
      convenio: new FormControl(null, Validators.nullValidator),

    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.nullValidator),
      instructor_id: new FormControl(null, Validators.required),
      instructor_nombre: new FormControl(null, Validators.nullValidator),
      instructor_nombre_2: new FormControl(null, Validators.nullValidator),
      instructor_nombre_3: new FormControl(null, Validators.nullValidator),

      licencia: new FormControl(null, Validators.nullValidator),

      tipo_curso_id: new FormControl(null, Validators.required),
      tipo_curso_nombre: new FormControl(null, Validators.nullValidator),
      categoria_nombre: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.nullValidator),
      duracion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),

      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      fecha_certificacion: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.nullValidator),
      convenio: new FormControl(null, Validators.nullValidator),

      supervisor: new FormControl(null, Validators.nullValidator),
      ayudante: new FormControl(null, Validators.nullValidator),
      codigo_ministerio: new FormControl(null, Validators.nullValidator),

      observaciones: new FormControl(null, Validators.nullValidator),

      // Campos - REQUISITOS DE INGRESO (Checkbox)
      req_habilitar: new FormControl(false, Validators.nullValidator),
      req_documento: new FormControl(false, Validators.nullValidator),
      req_concepto_medico: new FormControl(false, Validators.nullValidator),
      req_afiliaciones: new FormControl(false, Validators.nullValidator),
      req_certificado: new FormControl(false, Validators.nullValidator),

      // Campos - VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA (Select o Radio)
      cumpli_habilitar: new FormControl(false, Validators.nullValidator),
      cumpli_requisitos: new FormControl('', Validators.nullValidator),
      cumpli_disponibilidad: new FormControl('', Validators.nullValidator),
      cumpli_material: new FormControl('', Validators.nullValidator),
      cumpli_area: new FormControl('', Validators.nullValidator),
      cumpli_asistencia: new FormControl('', Validators.nullValidator),
      cumpli_evaluacion: new FormControl('', Validators.nullValidator),
      cumpli_cumpliento: new FormControl('', Validators.nullValidator),
      cumpli_apoyo: new FormControl('', Validators.nullValidator),

    });

    this.getListTipoCuros();
    this.getListInstructores();

  }

  async new() {
    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        ut: this.user.token,
        campos: this.nuevoForm.value,
        estado: 1,
      };
      this.appService.postCurso(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.nuevoForm.reset()
            // console.log(data["data"]);              
            // console.log(data['data'].id);              
            this.router.navigate(['/cursos/ver-curso/'] + data['data'].id);
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  getCurso() {
    let params = {
      get: true,
      et: this.user.et,
      id: this.id
    };
    // console.log(params);
    this.appService.postCurso(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.obj = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  getCertificados() {
    this.cargando = true;
    let params = {
      get: true,
      et: this.user.et,
      id: this.id
    };
    // console.log(params);
    this.appService.postCertificado(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objCertificados = data['obj'];
          // console.log(this.objCertificados);
        } else {
          this.toastr.error(data["msg"]);
        }
        this.cargando = false;
      });
    this.cargando = false;

  }

  getListTipoCuros() {
    let params = {
      get: true,
      et: this.user.et
    };
    // console.log(params);
    this.appService.postTipoCurso(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objTipoCurso = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  getListInstructores() {
    let params = {
      get: true,
      et: this.user.et
    };
    // console.log(params);
    this.appService.postInstructor(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objInstructor = data['obj'];
          // console.log(this.objInstructor);          
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  selectTipoCurso(item) {
    this.editForm.get('tipo_curso_id').setValue(item.id);
    this.editForm.get('tipo_curso_nombre').setValue(item.nombre);
    this.editForm.get('categoria_nombre').setValue(item.categoria_nombre);
    this.editForm.get('tiempo').setValue(item.tiempo);
    this.editForm.get('duracion').setValue(item.duracion);
    this.editForm.get('vigencia').setValue(item.vigencia);

    this.obj.tipo_curso_id = item.id;
    this.obj.tipo_curso_nombre = item.nombre;
    this.obj.categoria_nombre = item.categoria_nombre;
    this.obj.tiempo = item.tiempo;
    this.obj.duracion = item.duracion;
    this.obj.vigencia = item.vigencia;

    $('#tipocursoModal').modal('hide');
    $('#editCursoModal').modal('show');
  }

  selectInstructor(item) {
    this.editForm.get('instructor_id').setValue(item.id);
    this.editForm.get('instructor_nombre').setValue(item.nombre);
    this.editForm.get('licencia').setValue(item.licencia);

    this.obj.instructor_id = item.id;
    this.obj.instructor_nombre = item.nombre;
    this.obj.licencia = item.licencia;

    $('#instructorModal').modal('hide');
    $('#editCursoModal').modal('show');
  }



  async findCliente() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      et: this.user.et,
      page: 1,
    };
    this.appService.postClientes(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objClientes = data['clientes'];
          // console.log(this.clientes);              
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  certificarCliente(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea Certificar a ` + item.primer_nombre + ` ` + item.primer_apellido + `?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          nuevo: true,
          id: this.id,
          cliente_id: item.id,
          et: this.user.et,
          token: this.user.token,
          campos: this.obj,
        }
        this.appService.postCertificado(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.sendPushNotificacion();
              this.getCertificados();
              this.findInput = null;
              this.objClientes = [];
              $('#clienteModal').modal('hide');
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }

  eliminarCertificadoCliente(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea Eliminar el certificado de ' + item.primer_nombre + ' ' + item.primer_apellido + '?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          et: this.user.et,
          token: this.user.token,
        }
        this.appService.postCertificado(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getCertificados();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }

  cerrarCurso(item, estado) {
    let title = ''
    let text = ''
    if (estado == '1') {
      title = 'Cerrar Curso?'
      text = 'No podrá certificar a mas clientes en el!'
    }
    if (estado == '3') {
      title = 'Abrir Curso?'
      text = 'Desea abrir este curso?'
    }
    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          editarCierre: true,
          id: item.id,
          et: this.user.et,
          estado: estado,
          token: this.user.token,
        }
        // console.log(params);             
        this.appService.postCurso(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getCurso();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }

  sincronizarCurso() {
    let title = 'Sincronizar Curso'
    let text = 'Desea sicronizar la informacion del curso'

    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          sincronizar: true,
          id: this.id,
          et: this.user.et,
          token: this.user.token,
        }
        console.log(params);
        this.appService.postCertificado(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getCertificados();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }

  async exportCertificadoRelacion() {
    let params = {
      export: true,
      id: this.obj.id,
      et: this.user.et,
    };
    this.appService.postCertificado(params)
      .subscribe((data) => {
        // console.log(data['clientes']);          
        if (data["logger"] == true) {
          this.reporteExport = data['obj'];
          console.log(data['obj']);


          const fileName = 'curso_' + this.obj.fecha_inicio + '.xlsx';
          // const fileName = 'curso_' + this.obj.fecha_inicio + '.csv';
          const myHeader = ["tipo_documento", "documento", "primer_nombre", "segundo_nombre", "primer_apellido", "segundo_apellido", "genero", "pais_nacimiento", "fecha_nacimiento", "nivel_educativo", "area_trabajo", "cargo_actual", "sector", "instituto_nombre", "arl"];

          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

          // ANCHO COLUMNAS
          ws['!cols'] = [{ width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }];
          // ELIMINA COLUMNA1
          // ws['!cols'][0] = { hidden: true };
          // ws['!cols'][1] = { hidden: true };
          // ws['!cols'][16] = { hidden: true };
          // ws['!cols'][17] = { hidden: true };
          // ws['!cols'][18] = { hidden: true };

          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          // CAMBIA CABECERA
          XLSX.utils.sheet_add_aoa(ws, [["TIPO DOCUMENTO", "DOCUMENTO", "PRIMER NOMBRE", "SEGUNDO NOMBRE", "PRIMER APELLIDO", "SEGUNDO APELLIDO", "GENERO", "PAIS NACIMIENTO", "FECHA NACIMIENTO", "NIVEL EDUCACION", "AREA DE TRABAJO", "CARGO ACTUAL", "SECTOR", "EMPLEADOR", "ARL"]]);
          XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', header: myHeader, skipHeader: true });


          XLSX.utils.book_append_sheet(wb, ws, 'reporte');
          XLSX.writeFile(wb, fileName);

        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  selectPrint(item) {
    this.selectItem = item;
    this.urlSafe = null;
    console.log(this.selectItem);
    $('#seleccionModal').modal('show');
  }

  onCargaPdf(tipo, item) {
    console.log(item);
    let timerInterval
    Swal.fire({
      title: 'Generando Archivo!',
      html: 'Por favor espere<b></b>.',
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          // b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)

        // GENERARDOR DE PDF/////////////////////
        if (tipo == 'certificado') {
          this.objPlantillaOrder = item.plantilla_json;
          this.orientacionPlantilla = item.plantilla_orientacion;
        }
        if (tipo == 'carnet') {
          this.objPlantillaOrder = item.carnet_json;
          this.orientacionPlantilla = item.carnet_orientacion;
        }
        // console.log(this.selectItem);
        // console.log(this.objPlantillaOrder);
        if (!this.orientacionPlantilla) { this.orientacionPlantilla = 'l'; }

        // const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'letter');
        const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'A4');
        const width = doc.internal.pageSize.getWidth()
        const options = { background: 'white', scale: 3, };

        this.objPlantillaOrder.forEach(item => {
          // console.log(item.img_url);

          if (item.modulo == 'img') {
            let img = item.img_url;
            let newImg = img
              .replace("{certificado}", this.selectItem.plantilla_url)
              .replace("{carnet}", this.selectItem.carnet_url)
              .replace("{instructor_firma}", this.selectItem.instructor_firma_url)
              .replace("{representante_firma}", this.selectItem.representante_firma_url);
            // console.log(newImg);
            if (newImg !== 'null') {
              doc.addImage(newImg, "JPG", item.img_x, item.img_y, item.img_w, item.img_h);
            }
          }

          if (item.modulo == 'text') {
            let text = item.text_data;
            // const reg = /\d.*\d/;
            let nombre_cliente = "";
            if (this.selectItem.primer_nombre) { nombre_cliente = this.selectItem.primer_nombre }
            if (this.selectItem.segundo_nombre) { nombre_cliente = nombre_cliente + ' ' + this.selectItem.segundo_nombre }
            if (this.selectItem.primer_apellido) { nombre_cliente = nombre_cliente + ' ' + this.selectItem.primer_apellido }
            if (this.selectItem.segundo_apellido) { nombre_cliente = nombre_cliente + ' ' + this.selectItem.segundo_apellido }

            let newtext = text
              // .replace("{nombre}", this.selectItem.primer_nombre.toUpperCase() + ' ' + this.selectItem.segundo_nombre.toUpperCase()+ ' ' + this.selectItem.primer_apellido.toUpperCase()+ ' ' + this.selectItem.segundo_apellido.toUpperCase())
              .replace("{a}", "á")
              .replace("{e}", "é")
              .replace("{i}", "í")
              .replaceAll("{o}", "ó")
              .replace("{u}", 'ú')
              .replace("{nombre}", nombre_cliente.toUpperCase())
              .replace("{docu}", this.selectItem.tipo_documento.toUpperCase() + ' ' + this.selectItem.documento.toUpperCase())
              .replace("{curso}", this.selectItem.tipo_curso_nombre.toUpperCase())
              .replace("{categoria}", this.selectItem.categoria_nombre.toUpperCase())
              .replace("{ciudad}", this.selectItem.ciudad)
              .replace("{duracion}", this.selectItem.tiempo + ' ' + this.selectItem.duracion)
              .replace("{empresa}", this.selectItem.instituto_nombre.toUpperCase())
              .replace("{empresa_nit}", this.selectItem.instituto_nit)
              .replace("{empresa_representante}", this.selectItem.instituto_representante)
              .replace("{arl}", this.selectItem.arl.toUpperCase())
              .replace("{sector}", this.selectItem.sector)
              .replace("{fecha_inicio}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM'))
              .replace("{fecha_inicio_full}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM [de] YYYY'))
              .replace("{fecha_fin}", moment(this.selectItem.fecha_fin).locale('es').format('DD [de] MMMM [de] YYYY'))
              .replace("{fecha_certificacion}", moment(this.selectItem.fecha_certificacion).locale('es').format('LL'))
              .replace("{fecha_vencimiento}", moment(this.selectItem.fecha_vencimiento).locale('es').format('LL'))
              .replace("{instructor}", this.selectItem.instructor_nombre.toUpperCase())
              .replace("{instructor_licencia}", this.selectItem.instructor_licencia)
              .replace("{representante}", this.selectItem.representante_nombre)
              .replace("{representante_docu}", this.selectItem.representante_documento)
              .replace("{id}", this.selectItem.referencia + '' + this.selectItem.certificado);

            if (item.text_color) { doc.setTextColor(item.text_color); }
            if (item.text_size) { doc.setFontSize(item.text_size); }
            if (item.text_weight) { doc.setFont(undefined, item.text_weight); }
            if (item.text_align == 'center') {

              if (newtext != 'sector ') {
                doc.text(newtext, width / 2 + +item.text_x, item.text_y, null, item.text_align);
              }
            } else {
              if (newtext != 'sector ') {
                doc.text(newtext, item.text_x, item.text_y, null, item.text_align);
              }
            }
          }

          if (item.modulo == 'line') {
            doc.line(item.line_x1, item.line_y1, item.line_x2, item.line_y2);
          }
        });
        var out = doc.output();
        var url = 'data:application/pdf;base64,' + btoa(out);
        // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.cargandoExport = false;
        return doc.save(this.selectItem.tipo_curso_nombre + '-' + this.selectItem.instituto_nombre + '-' + this.selectItem.primer_nombre + '-' + this.selectItem.primer_apellido + '.pdf');

        // FIN GENERARDOR DE PDF/////////////////////

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('temporizador cerrado')
      }
    })



  }

  selectEditCurso() {
    this.editForm.get('instructor_id').setValue(this.obj.instructor_id);
    this.editForm.get('instructor_nombre').setValue(this.obj.instructor_nombre);
    this.editForm.get('licencia').setValue(this.obj.licencia);
    this.editForm.get('tipo_curso_id').setValue(this.obj.tipo_curso_id);
    this.editForm.get('tipo_curso_nombre').setValue(this.obj.tipo_curso_nombre);
    this.editForm.get('categoria_nombre').setValue(this.obj.categoria_nombre);
    this.editForm.get('tiempo').setValue(this.obj.tiempo);
    this.editForm.get('duracion').setValue(this.obj.duracion);
    this.editForm.get('vigencia').setValue(this.obj.vigencia);
    this.editForm.get('fecha_inicio').setValue(this.obj.fecha_inicio);
    this.editForm.get('fecha_fin').setValue(this.obj.fecha_fin);
    this.editForm.get('fecha_certificacion').setValue(this.obj.fecha_certificacion);
    this.editForm.get('ciudad').setValue(this.obj.ciudad);
    this.editForm.get('convenio').setValue(this.obj.convenio);

    $('#editCursoModal').modal('show');
  }

  async edit() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.id,
        et: this.user.et,
        ut: this.user.token,
        campos: this.editForm.value,
        estado: 1,
      };
      this.appService.postCurso(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.editForm.reset()
            $('#editCursoModal').modal('hide');
            this.getCurso();
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async editFormacionInforme() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.id,
        et: this.user.et,
        ut: this.user.token,
        campos: this.editForm.value,
        estado: 1,
      };
      this.appService.postCurso(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            // this.editForm.reset()
            // $('#editCursoModal').modal('hide');
            this.getCurso();
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  selectInformeSupervisor() {
    this.editForm.get('id').setValue(this.obj.id);
    this.editForm.get('instructor_id').setValue(this.obj.instructor_id);
    this.editForm.get('instructor_nombre').setValue(this.obj.instructor_nombre);

    this.editForm.get('instructor_nombre_2').setValue(this.obj.instructor_nombre_2);
    this.editForm.get('instructor_nombre_3').setValue(this.obj.instructor_nombre_3);


    this.editForm.get('licencia').setValue(this.obj.licencia);
    this.editForm.get('tipo_curso_id').setValue(this.obj.tipo_curso_id);
    this.editForm.get('tipo_curso_nombre').setValue(this.obj.tipo_curso_nombre);
    this.editForm.get('categoria_nombre').setValue(this.obj.categoria_nombre);
    this.editForm.get('tiempo').setValue(this.obj.tiempo);
    this.editForm.get('duracion').setValue(this.obj.duracion);
    this.editForm.get('vigencia').setValue(this.obj.vigencia);
    this.editForm.get('fecha_inicio').setValue(this.obj.fecha_inicio);
    this.editForm.get('fecha_fin').setValue(this.obj.fecha_fin);
    this.editForm.get('fecha_certificacion').setValue(this.obj.fecha_certificacion);
    this.editForm.get('ciudad').setValue(this.obj.ciudad);
    this.editForm.get('convenio').setValue(this.obj.convenio);
    this.editForm.get('supervisor').setValue(this.obj.supervisor);
    this.editForm.get('ayudante').setValue(this.obj.ayudante);
    this.editForm.get('codigo_ministerio').setValue(this.obj.codigo_ministerio);

    this.editForm.get('observaciones').setValue(this.obj.observaciones);

    // Nuevos campos - REQUISITOS DE INGRESO
    this.editForm.get('req_habilitar').setValue(this.obj.req_habilitar);
    this.editForm.get('req_documento').setValue(this.obj.req_documento);
    this.editForm.get('req_concepto_medico').setValue(this.obj.req_concepto_medico);
    this.editForm.get('req_afiliaciones').setValue(this.obj.req_afiliaciones);
    this.editForm.get('req_certificado').setValue(this.obj.req_certificado);

    // Nuevos campos - VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA
    this.editForm.get('cumpli_habilitar').setValue(this.obj.cumpli_habilitar);
    this.editForm.get('cumpli_requisitos').setValue(this.obj.cumpli_requisitos);
    this.editForm.get('cumpli_disponibilidad').setValue(this.obj.cumpli_disponibilidad);
    this.editForm.get('cumpli_material').setValue(this.obj.cumpli_material);
    this.editForm.get('cumpli_area').setValue(this.obj.cumpli_area);
    this.editForm.get('cumpli_asistencia').setValue(this.obj.cumpli_asistencia);
    this.editForm.get('cumpli_evaluacion').setValue(this.obj.cumpli_evaluacion);
    this.editForm.get('cumpli_cumpliento').setValue(this.obj.cumpli_cumpliento);
    this.editForm.get('cumpli_apoyo').setValue(this.obj.cumpli_apoyo);

    $('#informeSupervisorModal').modal('show');
  }

  // title = 'angular-pdf';

  // Datos del curso


  // generatePDF(dataForm: any) {
  //   const doc = new jsPDF();

  //   // Obtener la fecha actual y formatearla como DD/MM/AAAA
  //   const today = new Date();
  //   const day = String(today.getDate()).padStart(2, '0');
  //   const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son indexados desde 0
  //   const year = today.getFullYear();
  //   const formattedDate = `${day}/${month}/${year}`;

  //   // Datos del título, subtítulo y vigencia con la fecha actual
  //   const headerData = [
  //     [`VIGENCIA: ${formattedDate}`, 'Entrenamiento y capacitación\nFormato informe de supervisor de formación']
  //   ];

  //   // Crear tabla para título, subtítulo y vigencia con bordes
  //   (doc as any).autoTable({
  //     body: headerData,
  //     startY: 5,
  //     theme: 'grid',
  //     styles: {
  //       font: 'helvetica',
  //       fontStyle: 'bold',  // Configuración para texto en negrita
  //       cellPadding: 5,
  //       valign: 'middle'
  //     },
  //     columnStyles: {
  //       0: { cellWidth: 30, halign: 'center' },
  //       1: { cellWidth: 150, halign: 'center' }
  //     },
  //     didDrawCell: (data) => {
  //       if (data.column.index === 0) {
  //         doc.setFontSize(18); // Tamaño de fuente aumentado
  //         doc.setTextColor(40, 40, 40);
  //       } else if (data.column.index === 1) {
  //         doc.setFontSize(22); // Tamaño de fuente aumentado
  //         doc.setTextColor(100, 100, 100);
  //       }
  //       doc.setFont('helvetica', 'bold');
  //     }
  //   });


  //   // Función para ajustar el tamaño de la fuente según la longitud del texto
  //   const adjustFontSize = (text, maxChars) => {
  //     const baseFontSize = 9; // Tamaño de fuente base
  //     if (text.length > maxChars) {
  //       return baseFontSize * (maxChars / text.length); // Reducir el tamaño de fuente proporcionalmente
  //     }
  //     return baseFontSize; // Mantener el tamaño de fuente base si el texto es corto
  //   };


  //   // Datos del curso en tabla sin cabecera, convertidos a mayúscula y con fuente más pequeña
  //   const courseData = [
  //     ['Nombre del curso', (dataForm?.tipo_curso_nombre ?? '').toUpperCase()],
  //     ['Entrenador', (dataForm?.instructor_nombre ?? '').toUpperCase()],
  //     ['Supervisor', (dataForm?.supervisor ?? '').toUpperCase()],
  //     ['Ayudante de seguridad', (dataForm?.ayudante ?? '').toUpperCase()],
  //     ['Fecha de inicio', (dataForm?.fecha_inicio ?? '').toUpperCase()],
  //     ['Fecha de terminación', (dataForm?.fecha_fin ?? '').toUpperCase()]
  //   ];

  //   const maxCharsPerCell = 60; // Límite de caracteres para ajustar el tamaño de fuente

  //   // Estilos personalizados para la primera columna
  //   const firstColumnStyles = {
  //     fillColor: [0, 0, 128],
  //     textColor: [255, 255, 255],
  //     fontSize: 9, // Reducir tamaño de fuente
  //     halign: 'left'
  //   };

  //   // Estilos personalizados para la segunda columna
  //   const secondColumnStyles = {
  //     fillColor: [245, 245, 245],
  //     textColor: [0, 0, 0],
  //     fontSize: 9, // Reducir tamaño de fuente
  //     halign: 'left'
  //   };

  //   let startY = 30;
  //   const cellHeight = 10;
  //   const firstColumnWidth = 40;
  //   const secondColumnWidth = 140;
  //   const xOffset = 4; // Ajusta este valor según sea necesario

  //   courseData.forEach((row) => {
  //     // Calcular el tamaño de fuente para la primera columna
  //     const firstColumnFontSize = adjustFontSize(row[0], maxCharsPerCell);

  //     // Dibujar la celda de la primera columna
  //     doc.setFillColor(firstColumnStyles.fillColor[0], firstColumnStyles.fillColor[1], firstColumnStyles.fillColor[2]);
  //     doc.setTextColor(firstColumnStyles.textColor[0], firstColumnStyles.textColor[1], firstColumnStyles.textColor[2]);
  //     doc.setFontSize(firstColumnFontSize);
  //     const x1 = 10 + xOffset;
  //     const y1 = startY;
  //     doc.rect(x1, y1, firstColumnWidth, cellHeight, 'F'); // Dibujar el fondo de la celda
  //     doc.setDrawColor(0, 0, 128); // Color del borde
  //     doc.rect(x1, y1, firstColumnWidth, cellHeight); // Dibujar el borde de la celda
  //     doc.text(row[0], x1 + 2, y1 + 7); // Ajuste para el texto

  //     // Calcular el tamaño de fuente para la segunda columna
  //     const secondColumnFontSize = adjustFontSize(row[1], maxCharsPerCell);

  //     // Dibujar la celda de la segunda columna
  //     doc.setFillColor(secondColumnStyles.fillColor[0], secondColumnStyles.fillColor[1], secondColumnStyles.fillColor[2]);
  //     doc.setTextColor(secondColumnStyles.textColor[0], secondColumnStyles.textColor[1], secondColumnStyles.textColor[2]);
  //     doc.setFontSize(secondColumnFontSize);
  //     const x2 = 10 + firstColumnWidth + xOffset;
  //     const y2 = startY;
  //     doc.rect(x2, y2, secondColumnWidth, cellHeight, 'F'); // Dibujar el fondo de la celda
  //     doc.setDrawColor(203, 203, 203); // Color del borde
  //     doc.rect(x2, y2, secondColumnWidth, cellHeight); // Dibujar el borde de la celda
  //     doc.text(row[1], x2 + 2, y2 + 7); // Ajuste para el texto

  //     startY += cellHeight;
  //   });

  //   // Título del listado de usuarios con fondo de color
  //   const finalYCourse = startY || 40;
  //   doc.setFontSize(14);
  //   doc.setTextColor(0, 0, 0);
  //   doc.setFillColor(240, 240, 240);
  //   doc.rect(10, finalYCourse + 10, 190, 10, 'F');
  //   doc.text('Listado de Usuarios', 105, finalYCourse + 17, { align: 'center' });

  //   // Listado de usuarios con contador y estilos
  //   const userColumns = ['#', 'Cédula', 'Primer Nombre', 'Segundo Nombre', 'Primer Apellido', 'Segundo Apellido', 'Empresa'];
  //   const userRows = this.objCertificados.map((user, index) => [
  //     (index + 1).toString(), // Contador
  //     user.documento,
  //     user.primer_nombre,
  //     user.segundo_nombre,
  //     user.primer_apellido,
  //     user.segundo_apellido,
  //     user.instituto_nombre
  //   ]);

  //   (doc as any).autoTable({
  //     head: [userColumns],
  //     body: userRows,
  //     startY: finalYCourse + 25,
  //     theme: 'grid',
  //     headStyles: {
  //       fillColor: [0, 0, 128],
  //       textColor: [255, 255, 255],
  //       fontSize: 9
  //     },
  //     bodyStyles: {
  //       fillColor: [245, 245, 245]
  //     },
  //     alternateRowStyles: {
  //       fillColor: [255, 255, 255]
  //     },
  //     styles: {
  //       font: 'helvetica',
  //       fontSize: 10
  //     }
  //   });

  //   // Título del informe de supervisor con fondo de color
  //   const finalYUsers = (doc as any).lastAutoTable.finalY || finalYCourse + 25;
  //   doc.setFontSize(14);
  //   doc.setTextColor(0, 0, 0);
  //   doc.setFillColor(240, 240, 240);
  //   doc.rect(10, finalYUsers + 10, 190, 10, 'F');
  //   doc.text('Informe de Supervisor', 105, finalYUsers + 17, { align: 'center' });

  //   // Informe de supervisor en tabla sin cabecera y espacio reducido
  //   const supervisorData = [
  //     ['Estado del curso en el ministerio', 'CERRADO'],
  //     ['Código del ministerio', (dataForm?.codigo_ministerio ?? '').toUpperCase()],
  //     ['Se cumplieron los tiempos de la capacitación y entrenamiento?', 'SI'],
  //     ['Los talleres de formación fueron diligenciados en su totalidad por los aprendices? ', ''],
  //     ['La documentación entregada por el entrenador presenta tachones o enmendaduras?', ''],
  //     ['Se dispuso de personal de apoyo en cualquiera de las etapas de la formación?', ''],
  //     [{ content: 'Describe la actividad realizada por el personal de apoyo:', colSpan: 2 }], // Fila con una sola columna
  //     ['Existieron novedades en el curso?', 'NO'],
  //     ['Observaciones', 'NINGUNA']
  //   ];

  //   (doc as any).autoTable({
  //     body: supervisorData,
  //     startY: finalYUsers + 25,
  //     theme: 'grid',
  //     bodyStyles: {
  //       fillColor: [245, 245, 245]
  //     },
  //     alternateRowStyles: {
  //       fillColor: [255, 255, 255]
  //     },
  //     styles: {
  //       font: 'helvetica',
  //       fontSize: 10
  //     },
  //     showHead: 'firstPage', // No mostrar la cabecera
  //     margin: { top: 10 }, // Reducir el espacio superior
  //     didParseCell: function (data) {
  //       if (data.row.index === 6) { // Índice de la fila que quieres ajustar (0-based index)
  //         data.cell.styles.minCellHeight = 20; // Ajusta el valor según la altura deseada
  //       }
  //     }
  //   });


  //   // Campo de firma
  //   // const finalYTable = (doc as any).lastAutoTable.finalY || finalYUsers + 50;
  //   // doc.setFontSize(12);
  //   // doc.setTextColor(0, 0, 0);
  //   // const signatureLineWidth = 100;
  //   // const signatureLineX = (doc.internal.pageSize.getWidth() - signatureLineWidth) / 2;
  //   // doc.line(signatureLineX, finalYTable + 30, signatureLineX + signatureLineWidth, finalYTable + 30); // Línea para la firma
  //   // doc.text('Firma del Supervisor de Formación', 105, finalYTable + 40, { align: 'center' });

  //   // // Guardar el PDF
  //   // doc.save('informe_curso.pdf');

  //   // Campo de firma
  //   let finalYTable = (doc as any).lastAutoTable.finalY || finalYUsers + 50;
  //   doc.setFontSize(12);
  //   doc.setTextColor(0, 0, 0);

  //   // Verificar si hay suficiente espacio en la página actual
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   if (finalYTable + 50 > pageHeight) {
  //     doc.addPage(); // Agregar una nueva página si es necesario
  //     finalYTable = 30; // Reiniciar la posición Y en la nueva página
  //   }

  //   // Dibujar la línea de la firma
  //   const signatureLineWidth = 100;
  //   const signatureLineX = (doc.internal.pageSize.getWidth() - signatureLineWidth) / 2;
  //   doc.line(signatureLineX, finalYTable + 30, signatureLineX + signatureLineWidth, finalYTable + 30); // Línea para la firma
  //   doc.text('Firma del Supervisor de Formación', 105, finalYTable + 40, { align: 'center' });

  //   // Guardar el PDF
  //   doc.save('informe_curso.pdf');

  // }



  generatePDF(dataForm: any) {
    console.log(dataForm);

    const doc = new jsPDF();

    // Obtener la fecha actual y formatearla como DD/MM/AAAA
    const today = new Date();
    // const formattedDate = today.toLocaleDateString('es-CO');
    const formattedDate = dataForm?.fecha_fin
      ? dataForm.fecha_fin.split("-").reverse().join("/")
      : "";
    // Título principal con vigencia
    (doc as any).autoTable({
      body: [
        [`VIGENCIA: ${formattedDate}`, 'INFORME DEL SUPERVISOR DE FORMACION']
      ],
      startY: 5,
      theme: 'grid',
      styles: { font: 'helvetica', fontStyle: 'bold', cellPadding: 5, valign: 'middle' },
      columnStyles: { 0: { cellWidth: 30, halign: 'center' }, 1: { cellWidth: 150, halign: 'center' } }
    });

    // Datos del curso
    const courseData = [
      ['Nombre del curso', (dataForm?.tipo_curso_nombre ?? '').toUpperCase() + ' -  ' + (dataForm?.categoria_nombre ?? '').toUpperCase()],
      ['Entrenador 1', (dataForm?.instructor_nombre ?? '').toUpperCase()],
      ['Entrenador 2', (dataForm?.instructor_nombre_2 ?? '').toUpperCase()],
      ['Entrenador 3', (dataForm?.instructor_nombre_3 ?? '').toUpperCase()],
      ['Supervisor', (dataForm?.supervisor ?? '').toUpperCase()],
      ['Ayudante de seguridad', (dataForm?.ayudante ?? '').toUpperCase()],
      ['Fecha de inicio', (dataForm?.fecha_inicio ?? '').toUpperCase()],
      ['Fecha de terminación', (dataForm?.fecha_fin ?? '').toUpperCase()],
      ['Código Interno', (dataForm?.id ?? '').toUpperCase()],
      ['Código Ministerio', (dataForm?.codigo_ministerio ?? '').toUpperCase()]
    ];

    (doc as any).autoTable({
      body: courseData,
      startY: 30,
      theme: 'grid',
      styles: { font: 'helvetica', fontSize: 9 },
      columnStyles: {
        0: { fillColor: [0, 0, 128], textColor: [255, 255, 255], halign: 'left' },
        1: { fillColor: [245, 245, 245], textColor: [0, 0, 0], halign: 'left' }
      }
    });



    // Sección REQUISITOS DE INGRESO

    if (dataForm?.req_habilitar) {

      const requisitosHeader = [['REQUISITOS DE INGRESO', 'CUMPLE']];

      const requisitosData = [
        ['Copia de documento de identidad legible.', dataForm?.req_documento ? 'SI' : 'NO'],
        ['Soporte de pago o afiliaciones a seguridad social (ARL - Salud).', dataForm?.req_afiliaciones ? 'SI' : 'NO'],
        ['Concepto médico ocupacional con énfasis en alturas vigente.', dataForm?.req_concepto_medico ? 'SI' : 'NO'],
        ['Certificado o constancia de formación vocacional de curso nivel avanzado o trabajador autorizado.', dataForm?.req_certificado ? 'SI' : 'NO'],
      ];

      (doc as any).autoTable({
        head: requisitosHeader,
        body: requisitosData,
        startY: (doc as any).lastAutoTable.finalY + 10,
        theme: 'grid',
        styles: {
          font: 'helvetica',
          fontSize: 9,
          cellPadding: 1,
          valign: 'middle'
        },
        headStyles: {
          fillColor: [240, 240, 240], // Color gris para el encabezado
          textColor: [0, 0, 0],
          fontStyle: 'bold'
        },
        columnStyles: {
          0: { cellWidth: 152, halign: 'left' }, // Primera columna alineada a la izquierda
          1: { cellWidth: 30, halign: 'center', valign: 'middle' } // Segunda columna centrada
        },
        didParseCell: function (data) {
          if (data.row.section === 'head') {
            if (data.column.index === 0) {
              data.cell.styles.halign = 'left'; // Alinear "REQUISITOS DE INGRESO" a la izquierda
            } else if (data.column.index === 1) {
              data.cell.styles.halign = 'center'; // Alinear "CUMPLE" al centro
            }
          }
        }
      });

    }

    // Sección VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA DE FORMACIÓN Y ENTRENAMIENTO
    if (dataForm?.cumpli_habilitar) {

      const cumplimientoHeader = [['VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA DE FORMACIÓN Y ENTRENAMIENTO', 'C', 'NC', 'N/A']];
      const check = 'X';

      const cumplimientoData = [
        ['Requisitos de ingreso por parte de los aprendices y/o empleador de acuerdo con el programa de formación.',
          dataForm?.cumpli_requisitos === 'C' ? 'X' : '',
          dataForm?.cumpli_requisitos === 'NC' ? 'X' : '',
          dataForm?.cumpli_requisitos === 'N/A' ? 'X' : ''
        ],
        ['Disponibilidad de material, equipos, estructuras, material didáctico y demás elementos necesarios para cumplir con los objetivos del programa de formación.',
          dataForm?.cumpli_disponibilidad === 'C' ? 'X' : '',
          dataForm?.cumpli_disponibilidad === 'NC' ? 'X' : '',
          dataForm?.cumpli_disponibilidad === 'N/A' ? 'X' : ''
        ],
        ['Verificación del material, equipos, estructuras, material didáctico y demás elementos necesarios sean inspeccionados y se les realice mantenimiento para asegurar que se mantengan las condiciones de seguridad durante el desarrollo del programa de formación.',
          dataForm?.cumpli_material === 'C' ? 'X' : '',
          dataForm?.cumpli_material === 'NC' ? 'X' : '',
          dataForm?.cumpli_material === 'N/A' ? 'X' : ''
        ],
        ['Verificar que las áreas de entrenamiento estén restringidas durante los periodos de descanso.',
          dataForm?.cumpli_area === 'C' ? 'X' : '',
          dataForm?.cumpli_area === 'NC' ? 'X' : '',
          dataForm?.cumpli_area === 'N/A' ? 'X' : ''
        ],
        ['verificar la asistencia del 100% por parte de los aprendices de acuerdo con la intensidad horaria del programa de formación.',
          dataForm?.cumpli_asistencia === 'C' ? 'X' : '',
          dataForm?.cumpli_asistencia === 'NC' ? 'X' : '',
          dataForm?.cumpli_asistencia === 'N/A' ? 'X' : ''
        ],
        ['Verificar la aprobación de las evaluaciones de formación y entrenamiento de acuerdo con lo establecido con el programa de formación.',
          dataForm?.cumpli_evaluacion === 'C' ? 'X' : '',
          dataForm?.cumpli_evaluacion === 'NC' ? 'X' : '',
          dataForm?.cumpli_evaluacion === 'N/A' ? 'X' : ''
        ],
        ['Garantizar el cumplimiento del 100% del plan de estudios, de acuerdo con el programa de formación.',
          dataForm?.cumpli_cumpliento === 'C' ? 'X' : '',
          dataForm?.cumpli_cumpliento === 'NC' ? 'X' : '',
          dataForm?.cumpli_cumpliento === 'N/A' ? 'X' : ''
        ],
        ['Se garantiza durante la etapa de entrenamiento acompañamiento permanente de una (1) persona de apoyo, con capacidad de operar el plan de emergencia, labores de rescate, vigilar las actividades realizadas por los aprendices en entrenamiento y brindar soporte en la atención de primeros auxilios',
          dataForm?.cumpli_apoyo === 'C' ? 'X' : '',
          dataForm?.cumpli_apoyo === 'NC' ? 'X' : '',
          dataForm?.cumpli_apoyo === 'N/A' ? 'X' : ''
        ],
      ];

      // Agregar tabla con alineación y estilos
      (doc as any).autoTable({
        head: cumplimientoHeader,
        body: cumplimientoData,
        startY: (doc as any).lastAutoTable.finalY + 10,
        theme: 'grid',
        styles: {
          font: 'helvetica',
          fontSize: 9,
          cellPadding: 1,
          valign: 'middle'
        },
        headStyles: {
          fillColor: [240, 240, 240], // Color gris claro para el encabezado
          textColor: [0, 0, 0],
          fontStyle: 'bold'
        },
        columnStyles: {
          0: { cellWidth: 122, halign: 'left' }, // Primera columna alineada a la izquierda
          1: { cellWidth: 20, halign: 'center' }, // Columna C centrada
          2: { cellWidth: 20, halign: 'center' }, // Columna NC centrada
          3: { cellWidth: 20, halign: 'center' }  // Columna N/A centrada
        },
        didParseCell: function (data) {
          if (data.row.section === 'head') {
            if (data.column.index === 0) {
              data.cell.styles.halign = 'left'; // Alinear a la izquierda
            } else if (data.column.index === 1) {
              data.cell.styles.halign = 'center'; // Alinear 
            } else if (data.column.index === 2) {
              data.cell.styles.halign = 'center'; // Alinear 
            } else if (data.column.index === 3) {
              data.cell.styles.halign = 'center'; // Alinear
            }
          }
        }
      });

    }

    // Verifica si hay contenido en observaciones antes de agregar la sección
    if (dataForm?.observaciones) {
      // Espacio antes del título de observaciones
      const finalYBeforeObservaciones = (doc as any).lastAutoTable.finalY + 10;

      // Datos de la tabla Observaciones
      const observacionesHeader = [['OBSERVACIONES']];
      const observacionesData = [[dataForm?.observaciones]];

      // Generar la tabla de observaciones con estilo similar a la imagen
      (doc as any).autoTable({
        head: observacionesHeader,
        body: observacionesData,
        startY: finalYBeforeObservaciones,
        theme: 'grid',
        styles: {
          font: 'helvetica',
          fontSize: 9,
          cellPadding: 1,
          valign: 'middle',
        },
        headStyles: {
          fillColor: [240, 240, 240], // Gris claro para el encabezado
          textColor: [0, 0, 0],
          fontStyle: 'bold',
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 182, halign: 'left' }, // Ajustar la columna al ancho del PDF
        }
      });

      var finalYAfterObservaciones = (doc as any).lastAutoTable.finalY + 10;
    } else {
      var finalYAfterObservaciones = (doc as any).lastAutoTable.finalY + 10;
    }

    // Ahora agregamos el Listado de Usuarios DESPUÉS de las observaciones
    const finalYBeforeUsers = finalYAfterObservaciones;





    // Título del Listado de Usuarios con fondo gris
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFillColor(240, 240, 240);
    doc.rect(10, finalYBeforeUsers, 190, 10, 'F'); // Dibujar rectángulo de fondo gris
    doc.text('LISTADO DE APRENDICES', 105, finalYBeforeUsers + 7, { align: 'center' });

    // Tabla de Listado de Usuarios con nombres en formato correcto
    (doc as any).autoTable({
      head: [['#', 'Cédula', 'Primer Nombre', 'Segundo Nombre', 'Primer Apellido', 'Segundo Apellido', 'Empresa']],
      body: this.objCertificados.map((user, index) => [
        (index + 1).toString(),
        user.documento,
        user.primer_nombre ? user.primer_nombre.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '',
        user.segundo_nombre ? user.segundo_nombre.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '',
        user.primer_apellido ? user.primer_apellido.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '',
        user.segundo_apellido ? user.segundo_apellido.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '',
        user.instituto_nombre ? user.instituto_nombre.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : ''
      ]),
      startY: finalYBeforeUsers + 15,
      theme: 'grid',
      headStyles: { fillColor: [0, 0, 128], textColor: [255, 255, 255], fontSize: 9 },
      bodyStyles: { fillColor: [245, 245, 245] },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      styles: { font: 'helvetica', fontSize: 10 }
    });




    // Firma del supervisor
    let finalYTable = (doc as any).lastAutoTable.finalY + 30;
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    // Verificar si hay suficiente espacio en la página actual
    const pageHeight = doc.internal.pageSize.getHeight();
    if (finalYTable + 50 > pageHeight) {
      doc.addPage();
      finalYTable = 30;
    }

    // Dibujar la línea de la firma
    const signatureLineWidth = 100;
    const signatureLineX = (doc.internal.pageSize.getWidth() - signatureLineWidth) / 2;
    doc.line(signatureLineX, finalYTable + 30, signatureLineX + signatureLineWidth, finalYTable + 30);
    doc.text('Firma del Supervisor de Formación', 105, finalYTable + 40, { align: 'center' });

    // Guardar el PDF
    doc.save('informe_curso.pdf');
  }


  generateEXCEL(dataForm: any) {
    console.log(dataForm);
    const fileName = 'curso_' + (dataForm?.fecha_inicio ?? 'sin_fecha') + '.xlsx';

    // Datos del encabezado del curso con valores dinámicos
    const reportHeader = [
      ['VIGENCIA:', '23/02/2025', '', 'Entrenamiento y capacitación', 'Formato informe de supervisor de formación'],
      ['', '', '', '', ''],
      ['Nombre del curso', (dataForm?.tipo_curso_nombre ?? '').toUpperCase()],
      ['Entrenador', (dataForm?.instructor_nombre ?? '').toUpperCase()],
      ['Supervisor', (dataForm?.supervisor ?? '').toUpperCase()],
      ['Ayudante de seguridad', (dataForm?.ayudante ?? '').toUpperCase()],
      ['Fecha de inicio', (dataForm?.fecha_inicio ?? '').toUpperCase()],
      ['Fecha de terminación', (dataForm?.fecha_fin ?? '').toUpperCase()],
      ['', '', '', '', ''], // Espacio en blanco
    ];

    // Sección REQUISITOS DE INGRESO con check de aprobación
    let requisitosData: any[] = [
      ['REQUISITOS DE INGRESO', '', '', '', ''],
      ['Copia de documento de identidad legible.', dataForm?.req_documento ? '✔️' : '❌', '', 'Concepto médico ocupacional con énfasis en alturas vigente.', dataForm?.req_concepto_medico ? '✔️' : '❌'],
      ['Soporte de pago o afiliaciones a seguridad social (ARL - Salud).', dataForm?.req_afiliaciones ? '✔️' : '❌', '', 'Certificado o constancia de formación vocacional de curso nivel avanzado o trabajador autorizado.', dataForm?.req_certificado ? '✔️' : '❌'],
      ['', '', '', '', ''], // Espacio en blanco
    ];

    // Sección VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA DE FORMACIÓN Y ENTRENAMIENTO con columnas C, NC, N/A
    let cumplimientoData: any[] = [
      ['6. VERIFICACIÓN DEL CUMPLIMIENTO DEL PROGRAMA DE FORMACIÓN Y ENTRENAMIENTO', '', '', '', ''],
      ['ITEMS', 'C', 'NC', 'N/A'],
      ['Requisitos de ingreso por parte de los aprendices y/o empleador de acuerdo con el programa de formación.', dataForm?.cumpli_requisitos ?? '', '', ''],
      ['Disponibilidad de material, equipos, estructuras, material didáctico y demás elementos necesarios para cumplir con los objetivos del programa de formación.', dataForm?.cumpli_disponibilidad ?? '', '', ''],
      ['Verificación del material, equipos, estructuras, material didáctico y demás elementos necesarios sean inspeccionados y se les realice mantenimiento para asegurar que se mantengan las condiciones de seguridad durante el desarrollo del programa de formación.', dataForm?.cumpli_material ?? '', '', ''],
      ['Verificar que las áreas de entrenamiento estén restringidas durante los periodos de descanso.', dataForm?.cumpli_area ?? '', '', ''],
      ['Verificar la asistencia del 100% por parte de los aprendices de acuerdo con la intensidad horaria del programa de formación.', dataForm?.cumpli_asistencia ?? '', '', ''],
      ['Verificar la aprobación de las evaluaciones de formación y entrenamiento de acuerdo con lo establecido con el programa de formación.', dataForm?.cumpli_evaluacion ?? '', '', ''],
      ['Garantizar el cumplimiento del 100% del plan de estudios, de acuerdo con el programa de formación.', dataForm?.cumpli_cumpliento ?? '', '', ''],
      ['Se garantiza durante la etapa de entrenamiento acompañamiento permanente de una (1) persona de apoyo, con capacidad de operar el plan de emergencia, labores de rescate, vigilar las actividades realizadas por los aprendices en entrenamiento y brindar soporte en la atención de primeros auxilios.', dataForm?.cumpli_apoyo ?? '', '', ''],
      ['', '', '', '', ''], // Espacio en blanco
    ];

    // Encabezado del Listado de Usuarios
    const tableTitle = [['Listado de Usuarios']];

    // Encabezados de la tabla
    const tableHeaders = [
      ['#', 'Cédula', 'Primer Nombre', 'Segundo Nombre', 'Primer Apellido', 'Segundo Apellido', 'Empresa']
    ];

    // Datos de la tabla (usando this.objCertificados)
    const usersData = this.objCertificados.map((item, index) => [
      index + 1,
      item.documento,
      item.primer_nombre,
      item.segundo_nombre,
      item.primer_apellido,
      item.segundo_apellido,
      item.instituto_nombre
    ]);

    // Crear hoja de cálculo con toda la información
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
      ...reportHeader,
      ...requisitosData,
      ...cumplimientoData,
      ...tableTitle,
      ...tableHeaders,
      ...usersData
    ]);

    // Ajustar ancho de columnas
    ws['!cols'] = [
      { width: 60 },  // Descripción de requisitos y cumplimiento
      { width: 15 },  // Check (✔️/❌)
      { width: 5 },   // Espacio vacío
      { width: 60 },  // Descripción adicional
      { width: 15 }   // Check (✔️/❌)
    ];

    // Crear el libro de trabajo
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte');

    // Descargar el archivo
    XLSX.writeFile(wb, fileName);
  }


  sendPushNotificacion() {
    this.appService.getPush('aprobar' + '-' + this.user.perfil + '-' + this.user.ecu + '-' + this.id + '-' + this.user.eid)
      .subscribe((data) => {
        // console.log(data);
      },
        error => console.log(error),
      );
  }



}

<section class="content-header">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="consultaForm" (ngSubmit)="buscarTipoCurso()">
          <div class="row">
            <div class="col-12">
              <h3>Informe certificados</h3>
            </div>
            <div class="col-2">
              <div class="form-floating">
                <input formControlName="desde" placeholder="Desde" type="date" class="form-control" />
                <label>Desde <span class="text-danger">*</span></label>
              </div>
            </div>
            <div class="col-2">
              <div class="form-floating">
                <input formControlName="hasta" placeholder="Hasta" type="date" class="form-control" />
                <label>Hasta <span class="text-danger">*</span></label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-floating">
                <select formControlName="tipo_curso" class="form-select" placeholder="Pefil">
                  <option *ngFor="let item of obj" value="{{item.id}}"><span
                      *ngIf="item.categoria_nombre">{{item.categoria_nombre}} -</span> {{item.nombre}} </option>
                </select>
                <label for="">Curso <span class="text-danger">*</span></label>
              </div>
            </div>
            <div class="col-4">
              <div style="border:1px solid #ddd;border-radius: 4px; cursor: pointer;">
                <label style="color: #6f7174;font-size: 0.8em;margin: 5px 10px;margin-bottom: 0px;"  data-bs-toggle="modal"
                data-bs-toggle="modal" data-bs-target="#institutoModal">Seleccione
                  empresa</label>
                <div style="margin: 1px 10px;margin-bottom: 6px;" *ngIf="!this.consultaForm.value.instituto_nombre" data-bs-toggle="modal"
                data-bs-toggle="modal" data-bs-target="#institutoModal">Seleccione empresa</div>
                <div style="margin: 1px 10px;margin-bottom: 6px;" *ngIf="this.consultaForm.value.instituto_nombre">{{this.consultaForm.value.instituto_nombre}} <button class="btn btn-sm btn-primary" type="button" (click)="resetEmpresa()"><i class="fa fa-trash"></i></button></div>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-3 mb-3">
                <button type="submit" class="btn btn-lg btn-block btn-primary">
                  <span *ngIf="!cargando">Consultar</span>
                  <div *ngIf="cargando" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content m-3 mt-0" *ngIf="objCertificadosCount">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <h3 class="ml-3 mt-1">Registros encontrados <b>{{objCertificadosCount}} </b> <button
          class="btn btn-sm btn-success" [disabled]="!objCertificadosCount" (click)="exportInforme()"><i
            class="fa fa-file-excel"></i></button></h3>
      <hr>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Certificado</th>
            <th scope="col">Curso</th>
            <th scope="col">Categoría</th>
            <th scope="col">Duración</th>
            <th scope="col">Vencimiento</th>
            <th scope="col">Cliente</th>
            <th scope="col">Empresa</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of objCertificados">
            <td>{{item.referencia}} {{item.certificado}}</td>
            <td>{{item.tipo_curso_nombre}}</td>
            <td>{{item.categoria_nombre}}</td>
            <td>{{item.tiempo}} {{item.duracion}}</td>
            <td>{{item.fecha_vencimiento}}</td>
            <td>
              {{item.primer_nombre | titlecase}} {{item.segundo_nombre |
              titlecase}} {{item.primer_apellido | titlecase}} {{item.segundo_apellido | titlecase}}</td>
            <td>
              {{item.instituto_nombre | titlecase}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>


<!-- buscar Empresa Nuevo -->
<div class="modal fade" id="institutoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Empresa</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <div class="input-group mb-0">
            <input type="text" class="form-control" [(ngModel)]="findInputInstituto"
              placeholder="Buscador - Nombre o Nit">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findInstituto()"><i
                class="fa fa-search"></i></button>
          </div>
        </div>

        <div *ngFor="let item of objInstituto.items" class="list-group-item list-group-item-action" aria-current="true"
          (click)="selectEmpresa(item)" style="cursor: pointer;">
          <div class="row">
            <div class="col-10">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.nombre | titlecase}} {{item.apellido | titlecase}}</h5>
              </div>
              <p class="mb-1"><span class="text-muted">Representante:</span> {{item.representante}}</p>
              <p class="mb-1"><span class="text-muted" *ngIf="item.nit">Nit </span>{{item.nit }}</p>
            </div>
            <div class="col-2">
              <i class="fa fa-building" style="color: #ced4da;font-size: 3em;"></i>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-certificados-informe',
  templateUrl: './certificados-informe.component.html',
  styleUrls: ['./certificados-informe.component.scss']
})
export class CertificadosInformeComponent implements OnInit {
  public user: any = null;
  public consultaForm: FormGroup;
  public cargando = false;

  desde: any;
  hasta: any;
  tipo_curso: any;
  obj: any = [];
  objCertificados: any = [];
  objCertificadosCount: any;

  page = 1;
  findInputInstituto: any;
  objInstituto: any = [];

  constructor(private appService: AppService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getListTipoCurso();


    this.consultaForm = new FormGroup({
      desde: new FormControl(null, Validators.required),
      hasta: new FormControl(null, Validators.required),
      tipo_curso: new FormControl(null, Validators.required),
      instituto_id: new FormControl(null, Validators.nullValidator),
      instituto_nombre: new FormControl(null, Validators.nullValidator),
    });    
  }

   getListTipoCurso() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postTipoCurso(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.obj = data['obj'];
          // console.log(this.obj);
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  buscarTipoCurso(){
    this.cargando = true;
    if (this.consultaForm.valid) {
      let params = {
        fitro_certificados: true,
        et: this.user.et,
        campos: this.consultaForm.value,
      };
      this.appService.postCertificado(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.objCertificados = data['obj'];
            this.objCertificadosCount = data['count'];
            console.log(data);
          } else {
            this.toastr.error(data["msg"]);
          }
        }); 
    }else {
      this.toastr.error('El formulario no es válido!');
    }
    this.cargando = false;     

  }

  async findInstituto() {
    let params = {
      buscar: true,
      buscar_input: this.findInputInstituto,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postInstituto(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objInstituto = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  } 
  
  selectEmpresa(item) {
      this.consultaForm.get('instituto_id').setValue(item.id);
      this.consultaForm.get('instituto_nombre').setValue(item.nombre);
      // this.consultaForm.get('instituto_representante').setValue(item.representante);
      // this.consultaForm.get('instituto_nit').setValue(item.nit);
      // $('#ver').modal('show');
      $('#institutoModal').modal('hide');
  } 
  
  resetEmpresa(){
    this.consultaForm.get('instituto_id').setValue(null);
    this.consultaForm.get('instituto_nombre').setValue(null);
    console.log('resetEmpresa');
  }

  async exportInforme() {
    let timerInterval
    Swal.fire({
      title: 'Generando Archivo!',
      html: 'Por favor espere<b></b>.',
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          // b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)

        const fileName = 'informe_certificados' + this.consultaForm.value.desde +'-'+this.consultaForm.value.hasta+'.xlsx';
        const myHeader = ["certificado_id", "tipo_curso_nombre", "categoria_nombre", "tipo_curso_duracion", "fecha_vencimiento", "cliente_nombre", "instituto_nombre"];

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.objCertificados);

        // ANCHO COLUMNAS
        ws['!cols'] = [{ width: 25 }, { width: 35 }, { width: 55 }, { width: 15 }, { width: 25 }, { width: 75 }, { width: 75 }];
        // ELIMINA COLUMNA1
        ws['!cols'][7] = { hidden: true };
        ws['!cols'][8] = { hidden: true };
        ws['!cols'][9] = { hidden: true };
        ws['!cols'][10] = { hidden: true };
        ws['!cols'][11] = { hidden: true };
        ws['!cols'][12] = { hidden: true };
        ws['!cols'][13] = { hidden: true };
        ws['!cols'][14] = { hidden: true };
        ws['!cols'][15] = { hidden: true };

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // CAMBIA CABECERA
        XLSX.utils.sheet_add_aoa(ws, [["CERTIFICADO", "CURSO", "CATEGORIA", "DURACION", "VENCIMIENTO", "CLIENTE", "EMPRESA"]]);
        XLSX.utils.sheet_add_json(ws, this.objCertificados, { origin: 'A2', header: myHeader, skipHeader: true });


        XLSX.utils.book_append_sheet(wb, ws, 'reporte');
        XLSX.writeFile(wb, fileName);


      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('temporizador cerrado')
      }
    })

  
  }

}

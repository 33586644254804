<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-5">
            <h1>
              <div style="float: left;">Cursos </div>
              <div class="input-group mb-3 col-3">
                <div class="input-group" id="button-addon3">
                  <button type="button" (click)="getList()" *ngIf="!cargando" class="btn btn-primary"><i
                      class="fa fa-sync"></i> </button>
                  <button type="button" *ngIf="cargando" class="btn btn-primary">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                </div>
              </div>
            </h1>
          </div>
          <div class="col-7">
            <div class="input-group mb-0">
              <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
              <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findCliente()"><i
                  class="fa fa-search"></i></button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">

          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Cursos empresa</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Certificado</th>
            <th scope="col">Fecha</th>
            <th scope="col">Curso</th>
            <th scope="col">Convenio</th>
            <th scope="col">Nombre</th>
            <th scope="col">Identificación</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td style="cursor: pointer;" (click)="ver(item)">{{item.referencia}}{{item.certificado | titlecase}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.fecha_certificacion | fechaMoment1}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.tipo_curso_nombre | titlecase}}
              <b>{{item.categoria_nombre | titlecase}}</b>
            </td>
            <td>{{item.convenio | titlecase}}</td>
            <td>{{item.primer_nombre | titlecase}} {{item.segundo_nombre | titlecase}} {{item.primer_apellido | titlecase}} {{item.segundo_apellido | titlecase}}</td>
            <td>{{item.tipo_documento | uppercase}} {{item.documento}}</td>
            <td>

              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver Curso</span></li>
                </ul>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  
  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>


<!-- Modal Ver  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3>Certificado</h3>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <div class="row">
          <div class="col-md-6">
            <h2 class="text-center">{{itemVer.referencia}} {{itemVer.certificado}}</h2>
            <div class="row border mb-3 p-2" style="border-radius: 6px;">
              <div class="col-2"><i class="fa fa-clipboard" style="font-size: 3em;"></i></div>
              <div class="col-10">
                <h2 *ngIf="!itemVer.curso_id" class="mt-1">Seleccione Tipo Curso</h2>
                <div *ngIf="itemVer.curso_id" class="mt-1">
                  <h5>{{itemVer.tipo_curso_nombre | titlecase}}</h5>
                  <div class="d-flex w-100 justify-content-between">
                    <small>{{itemVer.categoria_nombre | titlecase}}</small>
                    <small>{{itemVer.tiempo}} {{itemVer.duracion | titlecase}}</small>
                    <small>Vigencia: {{itemVer.vigencia}}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="row  border mb-3 p-2" style="border-radius: 6px;">
              <div class="col-2"><i class="fa fa-clock" style="font-size: 3em;"></i></div>
              <div class="col-10">
                <div>
                  <div class="row">
                    <div class="col-md-6"> <small><span class="text-muted">Fecha Inicio</span></small></div>
                    <div class="col-md-6"><small>{{itemVer.fecha_inicio | fechaMoment1}}</small></div>
                  </div>

                  <div class="row">
                    <div class="col-md-6"> <small><span class="text-muted">Fecha Fin</span></small></div>
                    <div class="col-md-6"><small>{{itemVer.fecha_fin | fechaMoment1}}</small></div>
                  </div>

                  <div class="row">
                    <div class="col-md-6"> <small><span class="text-muted">Vencimiento</span></small></div>
                    <div class="col-md-6"><small>{{itemVer.fecha_vencimiento | fechaMoment1}}</small></div>
                  </div>

                </div>
              </div>
            </div>

            <div class="row  border mb-3 p-2" style="border-radius: 6px;">
              <div class="col-2"><i class="fa fa-building" style="font-size: 3em;"></i></div>
              <div class="col-10">
                <div>
                  <div><b>{{itemVer.instituto_nombre | titlecase}}</b></div>
                  <div><small><span class="text-muted">Representante</span> {{itemVer.instituto_representante |
                      titlecase}}</small></div>
                  <div><small><span class="text-muted">Nit</span> {{itemVer.instituto_nit}}</small></div>
                </div>
              </div>
            </div>

            <div class="row border mb-3 p-2" style="border-radius: 6px;">
              <div class="col-2"><i class="fa fa-user-graduate" style="font-size: 3em;"></i></div>
              <div class="col-10">
                <h2 *ngIf="!itemVer.instructor_id" class="mt-1">Seleccione Instructor</h2>
                <div *ngIf="itemVer.instructor_id" class="mt-1">
                  <h5>{{itemVer.instructor_nombre | titlecase}}</h5>
                  <small>{{itemVer.instructor_licencia}} </small>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mt-0">
            <div class="form-floating mt-5">
              <input id="" placeholder="nombre" type="text"
                value="{{itemVer.primer_nombre | titlecase}} {{itemVer.segundo_nombre | titlecase}} {{itemVer.primer_apellido | titlecase}} {{itemVer.segundo_apellido | titlecase}}" class="form-control" disabled />
              <label for="">Nombre</label>
            </div>
            <div class="form-floating mt-1">
              <input id="" placeholder="" type="text" value="{{itemVer.tipo_documento}} {{itemVer.documento }}"
                class="form-control" disabled />
              <label for="">Identificacion</label>
            </div>
            <div class="form-floating mt-1">
              <input id="" placeholder="" type="text" value="{{itemVer.arl | titlecase}}" class="form-control"
                disabled />
              <label for="">ARL</label>
            </div>
            <div class="form-floating mt-1">
              <input id="" placeholder="" type="text" value="Sector {{itemVer.sector }}" class="form-control"
                disabled />
              <label for="">Sector</label>
            </div>

            <div class="form-floating mt-1">
              <input id="" placeholder="" type="text" value="{{itemVer.convenio }}" class="form-control"
                disabled />
              <label for="">Convenio</label>
            </div>
          </div>

          <div>
            <div class="btn-group w-100" role="group" aria-label="Basic example">
              <button type="button" (click)="onCargaPdf('certificado')" class="btn btn-warning" [disabled]="!itemVer.plantilla_json">
                <span *ngIf="cargando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <i *ngIf="!cargando" class="fa fa-print"></i> Certificado
              </button>
              <button type="button" (click)="onCargaPdf('carnet')" class="btn btn-warning" [disabled]="!itemVer.carnet_json"><i class="fa fa-address-card"></i> Carnet</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
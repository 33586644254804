<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Informes</h1>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="row">
          <div class="col-lg-3 col-6" style="cursor: pointer;">
              <div class="small-box bg-primary" [routerLink]="['/informes/certificado-informe']">
                  <div class="inner">
                      <h3>Actividad</h3>
                      <p>Informe Certificados</p>
                  </div>
                  <div class="icon">
                      <i class="fa fa-clipboard-check"></i>
                  </div>
                  <div class="small-box-footer"></div>
              </div>
          </div>

          <!-- <div class="col-lg-3 col-6">
            <div class="small-box bg-info" [routerLink]="['/empresas']">
                <div class="inner">
                    <h3>Ingresos</h3>
                    <p>cursos expedidos</p>
                </div>
                <div class="icon">
                    <i class="fa fa-clipboard-check"></i>
                </div>
                <div class="small-box-footer"></div>
            </div>
          </div> -->
      </div>
  </div>
</section>
<div class="float-right d-none d-sm-block"><b>Versión</b> 2.59</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://servidigital.co"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        servidigital.co</a
    >
    <span>.</span>
</strong>
<!-- <span> All rights reserved.</span> appVersion -->

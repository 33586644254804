import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var $: any;

import { jsPDF } from "jspdf";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';


@Component({
  selector: 'app-empresa-curso',
  templateUrl: './empresa-curso.component.html',
  styleUrls: ['./empresa-curso.component.scss']
})
export class EmpresaCursoComponent implements OnInit {
  public isAuthLoading = false;

  public user: any = null;
  reporteExport:any;
  findInput: any;
  obj: any = [];
  itemVer: any= [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;
  public loadingImg = false;

  selectItem: any = [];
  objPlantillaOrder: any = [];
  orientacionPlantilla:any;
  urlSafe: SafeResourceUrl;

  constructor(public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();  
  }

  getList(){   
    this.cargando = true;
    this.appService.getCertificadoEmpresa(this.user.et+'-'+this.user.inst+'-'+this.page).subscribe( (data) => {
    this.obj = data['obj']; 
    // this.ForpagesNumber();
    this.cargando = false;
    });
  }


  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.obj.total_page){
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  ver(item){
    // console.log(item);
    this.itemVer = item;
    $('#ver').modal('show');
  }


  async findCliente() {     
    let params = {
        buscador:true,
        buscar_input: this.findInput,
        instituto_id: this.user.inst,
        page: this.page,
        et: this.user.et,
      };
      this.cargando = true;
        this.appService.postCertificadoEmpresa(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.obj = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
            this.cargando = false;
        });

  }

  async exportCliente() {     
    let params = {
        export:true,
        et: this.user.et,
      };
        this.appService.postClientes(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.reporteExport = data['clientes'];

              const fileName = 'clientes.xlsx';
              const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);
        
              // ANCHO COLUMNAS
              ws['!cols'] = [ {width:2},{width:8},{width:35}, {width:35},{width:20},{width:20},{width:20},{width:20},{width:20},{width:20}];
              // ELIMINA COLUMNA1
              ws['!cols'][0] = { hidden: true };
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              // CAMBIA CABECERA
              XLSX.utils.sheet_add_aoa(ws, [["log","ID","NOMBRE","EMAIL","CELULAR","CIUDAD","NOMBRE"]]);
              XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  
        
              XLSX.utils.book_append_sheet(wb, ws, 'reporte');
              XLSX.writeFile(wb, fileName);

            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }  

  onCargaPdf(tipo) {
    this.cargando = true;
    this.selectItem = this.itemVer;
    if (tipo == 'certificado') {
      this.objPlantillaOrder = this.itemVer.plantilla_json;
      this.orientacionPlantilla = this.itemVer.plantilla_orientacion;
    }
    if (tipo == 'carnet') {
      this.objPlantillaOrder = this.itemVer.carnet_json;
      this.orientacionPlantilla = this.itemVer.carnet_orientacion;
    }
    if(!this.orientacionPlantilla){ this.orientacionPlantilla = 'l';}

    const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'A4');
    const width = doc.internal.pageSize.getWidth()
    const options = { background: 'white', scale: 3, };

    this.objPlantillaOrder.forEach(item => {

      if (item.modulo == 'img') {
        let img = item.img_url;
        let newImg = img
          .replace("{certificado}", this.selectItem.plantilla_url)
          .replace("{carnet}", this.selectItem.carnet_url)
          .replace("{instructor_firma}", this.selectItem.instructor_firma_url)
          .replace("{representante_firma}", this.selectItem.representante_firma_url);
        // console.log(newImg);
        if (newImg !== 'null') {
          doc.addImage(newImg, "JPG", item.img_x, item.img_y, item.img_w, item.img_h);
        }
      }

      if (item.modulo == 'text') {
        let text = item.text_data;
        // const reg = /\d.*\d/;
        let nombre_cliente = "";
        if(this.selectItem.primer_nombre){ nombre_cliente = this.selectItem.primer_nombre }
        if(this.selectItem.segundo_nombre){ nombre_cliente = nombre_cliente+' '+this.selectItem.segundo_nombre }
        if(this.selectItem.primer_apellido){ nombre_cliente = nombre_cliente+' '+this.selectItem.primer_apellido }
        if(this.selectItem.segundo_apellido){ nombre_cliente = nombre_cliente+' '+this.selectItem.segundo_apellido }


        let newtext = text
          .replace("{a}", "á")
          .replace("{e}", "é")
          .replace("{i}", "í")
          .replaceAll("{o}", "ó")
          .replace("{u}", 'ú')
          .replace("{nombre}", nombre_cliente.toUpperCase())
          .replace("{docu}", this.selectItem.tipo_documento.toUpperCase() + ' ' + this.selectItem.documento.toUpperCase())
          .replace("{curso}", this.selectItem.tipo_curso_nombre.toUpperCase())
          .replace("{categoria}", this.selectItem.categoria_nombre.toUpperCase())
          .replace("{ciudad}", this.selectItem.ciudad)
          .replace("{duracion}", this.selectItem.tiempo + ' ' + this.selectItem.duracion)
          .replace("{empresa}", this.selectItem.instituto_nombre.toUpperCase())
          .replace("{empresa_nit}", this.selectItem.instituto_nit)
          .replace("{empresa_representante}", this.selectItem.instituto_representante)
          .replace("{arl}", this.selectItem.arl.toUpperCase())
          .replace("{sector}", this.selectItem.sector)
          .replace("{fecha_inicio}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM'))
          .replace("{fecha_inicio_full}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM [de] YYYY'))
          .replace("{fecha_fin}", moment(this.selectItem.fecha_fin).locale('es').format('DD [de] MMMM [de] YYYY'))
          .replace("{fecha_certificacion}", moment(this.selectItem.fecha_certificacion).locale('es').format('LL'))
          .replace("{fecha_vencimiento}", moment(this.selectItem.fecha_vencimiento).locale('es').format('LL'))
          .replace("{instructor}", this.selectItem.instructor_nombre.toUpperCase())
          .replace("{instructor_licencia}", this.selectItem.instructor_licencia)
          .replace("{representante}", this.selectItem.representante_nombre)
          .replace("{representante_docu}", this.selectItem.representante_documento)
          .replace("{id}", this.selectItem.referencia + '' + this.selectItem.certificado);

        if (item.text_color) { doc.setTextColor(item.text_color); }
        if (item.text_size) { doc.setFontSize(item.text_size); }
        if (item.text_weight) { doc.setFont(undefined, item.text_weight); }
        if (item.text_align == 'center') {
          doc.text(newtext, width / 2 + +item.text_x, item.text_y, null, item.text_align);
        } else {
          doc.text(newtext, item.text_x, item.text_y, null, item.text_align);
        }
      }

      if (item.modulo == 'line') {
        doc.line(item.line_x1, item.line_y1, item.line_x2, item.line_y2);
      }
    });
    var out = doc.output();
    var url = 'data:application/pdf;base64,' + btoa(out);
    // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.cargando = false;
    return doc.save(this.selectItem.tipo_curso_nombre + '-' + this.selectItem.instituto_nombre+'-'+this.selectItem.primer_nombre + '-' + this.selectItem.primer_apellido + '.pdf');
    // return doc.save(`${new Date().toISOString()}_certificado.pdf`);

  } 


}

import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '@services/app.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private appService: AppService, private http: HttpClient) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            return this.getProfile1();
        }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            return true ;
            // return this.canActivate(next, state);
        }

    async getProfile1() {
        if (this.appService.user) {
            return true;
        }else{            
            let params = {
                validToken:true,
                token: sessionStorage.getItem('token'),
                // token: localStorage.getItem('token'),
              };
            this.http.post(AppService.baseURL+'login',params)
            .subscribe( (data) => {
                if (data["logger"] == true){
                    this.appService.user = data;
                    this.router.navigate(['/']);
                    return true;
                }else{
                    this.appService.logout();
                    return false;
                }
            });
        }

    }
}
